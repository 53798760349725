import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser($key: String!, $value: String!, $userid: String!) {
    updateUser(key: $key, value: $value, userid: $userid) {
      status
      code
      data
      error
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $email: String!
    $auth0_id: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      email: $email
      auth0_id: $auth0_id
    )
  }
`;

export const UPDATE_SIGNATURE = gql`
  mutation UpdateSignOrProfile($file: Upload!, $type: String!, $id: String!) {
    updateSignOrProfile(file: $file, type: $type, id: $id) {
      status
      code
      data
      error
    }
  }
`;
