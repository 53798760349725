interface ButtonsManifestProps {
  field: any;
  options: string[];
}

const ButtonsManifest: React.FC<ButtonsManifestProps> = ({
  field,
  options,
}: ButtonsManifestProps) => {
  return (
    <div className="flex w-full justify-center gap-2 ">
      {options.map((option: string) => {
        return (
          <button
            className={`${
              field.value === option
                ? 'bg-ReyesGreen text-white'
                : 'bg-white px-4 shadow'
            } inline-flex h-12 max-w-max items-center justify-start rounded-lg px-4  text-center shadow`}
            type="button"
            id={`${option}button`}
            onClick={() => field.onChange(option)}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonsManifest;
