export const OIL_DISPOSAL_SERVICE_NAME = 'Oil Disposal';
export const HOOD_CLEANING_SERVICE_NAME = 'Hood Cleaning';
export const GREASE_TRAP_SERVICE_NAME = 'Grease Trap Cleaning';
export const FAT_BONES_SERVICE_NAME = 'Meat, Fat & Bone';

export const services = [
  OIL_DISPOSAL_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  FAT_BONES_SERVICE_NAME,
];
