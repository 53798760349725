export const formatNumber = (num: string | number) => {
  if (typeof num === 'string')
    return Intl.NumberFormat('en-US').format(parseInt(num));
  return Intl.NumberFormat('en-US').format(num);
}

export const formatNumberMoney = (num: string | number) => {
  if (typeof num === 'string')
    return Intl.NumberFormat('en-US', { style: 'currency', currency: "USD" }).format(parseInt(num));
  return Intl.NumberFormat('en-US', { style: 'currency', currency: "USD" }).format(num);
}

export const formatNumberPercentage = (num: string | number) => {
  if (typeof num === 'string')
    return Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(parseInt(num));
  return Intl.NumberFormat('en-US', { style: 'percent',  maximumFractionDigits: 2 }).format(num);
}

export const inputFormatNumber = (num: number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const inputFormatNumberMoney = (num: number) => {
  return '$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};