import { useMutation } from '@apollo/client';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { formatDateWithTime } from '../../hooks/dateFormatter';
import ImageWithAuth from '../../hooks/useDriverSignature';
import { useRouteData } from '../../hooks/useRouteData';
import { ReactComponent as ArrowIcon } from '../../icons/arrowcircleright.svg';
import { UPDATE_STOP } from '../../services/graphql/stops/stopSkipped';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { mutationStateAtom } from '../../services/recoil/atoms/mutationState';
import { activeStopStateAtom } from '../../services/recoil/atoms/stopState';
import { Loading } from '../atoms/Loader';
import Title from '../atoms/Title';
import Layout from '../templates/Layout';

interface SkipFormData {
  reason: 'No Parking' | 'Client Absent' | 'Truck Accident' | '';
  stopId: string;
}

function SkipForm() {
  const stop = useRecoilValue(activeStopStateAtom);
  const navigate = useNavigate();

  if (!stop) {
    navigate('/dash');
    return null;
  }
  const { t } = useTranslation();
  const currentDate = new Date();
  const formattedDate = formatDateWithTime(currentDate);
  const driver = useRecoilValue(driverStateAtom);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SkipFormData>({
    defaultValues: {
      reason: '',
      stopId: stop.id,
    },
  });
  const setMutationState = useSetRecoilState(mutationStateAtom);
  const { refetch } = useRouteData(driver?.id);

  const handleMutation = () => {
    setMutationState((prevState) => !prevState);
  };
  const [updateStop] = useMutation(UPDATE_STOP);
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<SkipFormData> = async (data) => {
    setLoading(true);
    await updateStop({
      variables: {
        stopId: stop.id,
        reason: data.reason,
      },
    });
    await refetch();
    handleMutation();
    navigate('/firststop');
    setLoading(false);
  };
  return (
    <Layout>
      {loading ? (
        <Loading size={50} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto max-w-screen-md">
            <Title text={t('skipStopForm.title')}></Title>
            <div className="flex flex-col gap-6">
              <h2 className="text-lg font-semibold text-ReyesBlack ">
                {stop.clientName} {t('skipStopForm.skipStop')}
              </h2>
              <span>
                <p className="my-4 text-sm text-ReyesBlack">
                  {t('skipStopForm.date')}
                </p>
                <p className="text-sm  font-semibold  text-ReyesGrey">
                  {formattedDate}
                </p>
              </span>
              <span>
                <p className="my-4 text-sm text-ReyesBlack">
                  {t('skipStopForm.question')}
                </p>
                <div className="container flex justify-between gap-4">
                  <Controller
                    name="reason"
                    control={control}
                    rules={{ required: `${t('manifest.error1')}` }}
                    render={({ field }) => (
                      <div className="mt-4 flex gap-2 ">
                        <button
                          className={`${
                            field.value === 'No Parking'
                              ? 'bg-ReyesGreen text-white'
                              : 'bg-white px-4 shadow'
                          } inline-flex h-12 max-w-max items-center justify-start rounded-lg px-4  text-center shadow`}
                          type="button"
                          id="NoParking"
                          onClick={() => field.onChange('No Parking')}
                        >
                          {t('skipStopForm.noParking')}
                        </button>

                        <button
                          className={`${
                            field.value === 'Client Absent'
                              ? 'bg-ReyesGreen text-white'
                              : 'bg-white px-4 shadow'
                          } inline-flex h-12 max-w-max items-center justify-start rounded-lg px-4  text-center shadow`}
                          type="button"
                          onClick={() => field.onChange('Client Absent')}
                        >
                          {t('skipStopForm.clientAbsent')}
                        </button>

                        <button
                          className={`${
                            field.value === 'Truck Accident'
                              ? 'bg-ReyesGreen text-white'
                              : 'bg-white px-4 shadow'
                          } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                          type="button"
                          onClick={() => field.onChange('Truck Accident')}
                        >
                          {t('skipStopForm.truckAccident')}
                        </button>
                        {errors.reason && (
                          <span className="text-red-500">
                            {errors.reason.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </div>
              </span>
              <span>
                <p className="my-4 text-sm text-ReyesBlack">
                  {t('skipStopForm.driverSignature')}
                </p>
                <ImageWithAuth />
              </span>
              <div className="container">
                <button
                  type="submit"
                  id="SkipSubmitButton"
                  className="mt-8 inline-flex h-12 w-80 items-center justify-start gap-2.5 rounded bg-ReyesGold px-2.5 py-2 text-center text-base font-medium text-white shadow-custom"
                >
                  {t('skipStopForm.skipStop')} <ArrowIcon />
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Layout>
  );
}

export default SkipForm;
