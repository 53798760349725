import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RouteCardButtonProps {
  onManifestClick: () => void;
  onSkipStopClick: () => void;
  longitude: any;
  latitude: any;
  onDelayStopClick: () => void;
  onFocusClick: () => void;
}

const RouteCardButton: React.FC<RouteCardButtonProps> = ({
  onManifestClick,
  onSkipStopClick,
  longitude,
  latitude,
  onDelayStopClick,
  onFocusClick,
}) => {
  const [showNextButtons, setShowNextButtons] = useState(false);

  const handleNextClick = () => {
    setShowNextButtons(true);
    onFocusClick();
  };

  const { t } = useTranslation();

  return (
    <div className="my-2 flex h-fit w-full flex-col items-center whitespace-nowrap">
      {showNextButtons ? (
        <div className="w-full">
          <div className="w-full justify-between">
            <div className="flex w-full flex-row justify-between">
              <button
                className="inline-flex h-[30px] flex-col items-start justify-center self-stretch rounded bg-gray-700 px-3.5 py-1 text-center text-sm font-normal text-white"
                onClick={onManifestClick}
                id="ManifestButton"
              >
                {t('routeCardButton.routeCard1')}
              </button>
              <button
                className="inline-flex h-[30px] flex-col items-start justify-center self-stretch rounded bg-gray-500 px-3.5 py-1 text-center text-sm font-normal text-white"
                onClick={onSkipStopClick}
                id="SkipButton"
              >
                {t('routeCardButton.routeCard2')}
              </button>
              <a
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="text-overflow-ellipsis inline-flex h-[30px] flex-col items-start justify-center self-stretch overflow-hidden rounded bg-gray-400 px-3.5 py-1 text-center text-sm font-normal text-white"
                href={`${process.env.REACT_APP_MAP_URL}${longitude},${latitude}`}
                id="MapButton"
              >
                {t('routeCardButton.routeCard3')}
              </a>
            </div>
            <button
              onClick={onDelayStopClick}
              id="DelayButton"
              className="mt-2 min-w-full text-xs font-normal text-black underline"
            >
              {t('routeCardButton.routeCard4')}
            </button>
          </div>
        </div>
      ) : (
        <button onClick={handleNextClick} id="MarkNextStop" className="w-full">
          <div className=" inline-flex h-[30px] w-full flex-col items-start justify-center rounded bg-gray-700 px-4 py-1">
            <div className="self-stretch text-center text-sm font-normal text-white ">
              {t('routeCardButton.routeCard5')}
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default RouteCardButton;
