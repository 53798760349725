import { useTranslation } from 'react-i18next';

import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../config/constants';
import { ReactComponent as Bone } from '../../icons/bone.svg';
import { ReactComponent as BuildingIcon } from '../../icons/building.svg';
import { ReactComponent as DoneIcon } from '../../icons/clipboardtick.svg';
import { ReactComponent as GreaseTrapIcon } from '../../icons/grease-trap.svg';
import { ReactComponent as HoodCleaningIcon } from '../../icons/hood-cleaning.svg';
import { ReactComponent as LocationIcon } from '../../icons/location.svg';
import { ReactComponent as SkippedIcon } from '../../icons/locationcross.svg';
import { ReactComponent as OilIcon } from '../../icons/Oil.svg';

interface CardStopProps {
  businessName: string;
  gallonsEstimate?: number;
  barrelsEstimate?: number;
  addressLine1: string;
  status: string;
  service: string;
}

const CardStopSummary: React.FC<CardStopProps> = ({
  businessName,
  gallonsEstimate,
  barrelsEstimate,
  addressLine1,
  status,
  service,
}: CardStopProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <div className="flex flex-row justify-start gap-2">
            <div className="w-6">
              <BuildingIcon width="24" height="24" />
            </div>
            <span>
              <p className="text-ellipsis">{businessName}</p>
            </span>
          </div>
          <div className="flex items-center gap-2 align-baseline">
            {(status === 'Completed' ||
              status === 'In Review' ||
              status === 'Waiting Payment') && (
              <div className="w-6">
                <DoneIcon width="24" height="24" />
              </div>
            )}
            {status === 'Skipped' && (
              <div className="w-6">
                <SkippedIcon width="24" height="24" />
              </div>
            )}
            <span>
              {' '}
              {status === 'Completed' ||
              status === 'In Review' ||
              status === 'Waiting Payments'
                ? t('infoCard.done')
                : status}
            </span>
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-2">
            {service !== HOOD_CLEANING_SERVICE_NAME && (
              <div className="flex items-center gap-2">
                <div className="w-6">
                  {service === OIL_DISPOSAL_SERVICE_NAME && (
                    <OilIcon width="24" height="24" />
                  )}
                  {service === HOOD_CLEANING_SERVICE_NAME && (
                    <HoodCleaningIcon width="24" height="24" />
                  )}
                  {service === GREASE_TRAP_SERVICE_NAME && (
                    <GreaseTrapIcon width="24" height="24" />
                  )}
                  {service === FAT_BONES_SERVICE_NAME && (
                    <Bone width="24" height="24" />
                  )}
                </div>
                {service !== FAT_BONES_SERVICE_NAME &&
                  service !== HOOD_CLEANING_SERVICE_NAME && (
                    <div>{gallonsEstimate?.toLocaleString('en-US')} Gal</div>
                  )}
                {service === FAT_BONES_SERVICE_NAME && (
                  <div>{barrelsEstimate?.toLocaleString('en-US')} Barrels</div>
                )}
              </div>
            )}
            <div className="mr-6 flex items-center gap-2">
              <div className="w-6">
                <LocationIcon width="24" height="24" />
              </div>
              <span className="line-clamp-2 truncate whitespace-normal">
                {addressLine1}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 h-px w-60 bg-gray-300" />
    </>
  );
};

export default CardStopSummary;
