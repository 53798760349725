import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../config/constants';
import { ReactComponent as Bone } from '../../icons/bone.svg';
import { ReactComponent as BuildingIcon } from '../../icons/building.svg';
import { ReactComponent as GreaseTrapIcon } from '../../icons/grease-trap.svg';
import { ReactComponent as HoodCleaningIcon } from '../../icons/hood-cleaning.svg';
import { ReactComponent as LocationIcon } from '../../icons/location.svg';
import { ReactComponent as OilIcon } from '../../icons/Oil.svg';
import { activeStopStateAtom } from '../../services/recoil/atoms/stopState';
import RouteCardButton from '../atoms/RouteCardButton';

interface CardStopProps {
  businessName: string;
  gallonsEstimate: number | string;
  barrelsEstimate: number | string;
  addressLine1: string;
  isActive: boolean;
  shouldShowButtons: boolean;
  service: string;
}

const CardStop: React.FC<CardStopProps> = ({
  businessName,
  gallonsEstimate,
  barrelsEstimate,
  addressLine1,
  isActive,
  shouldShowButtons,
  service,
}) => {
  const [backgroundColor, setBackgroundColor] = useState('');
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const activeStopState = useRecoilValue(activeStopStateAtom);
  const cardInactiveStyle = 'bg-appBg';
  const cardSelectedStyle = 'bg-amber-100';
  const cardOnFocusStyle = 'bg-amber-200';

  useEffect(() => {
    if (isActive && !hasBeenClicked) {
      setBackgroundColor(cardSelectedStyle);
      setHasBeenClicked(true);
    } else if (!isActive) {
      setBackgroundColor(cardInactiveStyle);
      setHasBeenClicked(false);
    }
  }, [isActive]);

  const handleOnActive = () => {
    if (!hasBeenClicked) {
      setBackgroundColor(cardSelectedStyle);
      setHasBeenClicked(true);
      setShowButtons(true);
    }
  };
  const navigate = useNavigate();
  const handleManifest = () => {
    navigate('/manifest');
  };
  const handleSkipStop = () => {
    navigate('/skipstop');
  };
  let longitude = 0;
  let latitude = 0;
  if (
    activeStopState &&
    activeStopState.clientCoords &&
    activeStopState.clientCoords.coordinates
  ) {
    const { clientCoords } = activeStopState;
    [longitude, latitude] = clientCoords.coordinates;
  }

  const handleDelayStop = () => {
    setBackgroundColor(cardInactiveStyle);
    setHasBeenClicked(false);
    setShowButtons(false);
  };
  const handleOnFocusClick = () => {
    setBackgroundColor(cardOnFocusStyle);
  };

  return (
    <div>
      <div
        className={`rounded-2xl px-3 py-2 text-sm font-normal text-ReyesBlack ${backgroundColor}`}
        onClick={handleOnActive}
        id={businessName.replace(/\s/g, '')}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            <BuildingIcon style={{ width: '20px', height: '20px' }} />
            <p className="truncate">{businessName}</p>
          </div>
          <div className="flex items-center gap-1">
            {service === OIL_DISPOSAL_SERVICE_NAME && (
              <OilIcon width="24" height="24" />
            )}
            {service === HOOD_CLEANING_SERVICE_NAME && (
              <HoodCleaningIcon width="24" height="24" />
            )}
            {service === GREASE_TRAP_SERVICE_NAME && (
              <GreaseTrapIcon width="24" height="24" />
            )}
            {service === FAT_BONES_SERVICE_NAME && (
              <Bone width="24" height="24" />
            )}
            {service !== FAT_BONES_SERVICE_NAME &&
              service !== HOOD_CLEANING_SERVICE_NAME && (
                <p>
                  {typeof gallonsEstimate === 'number'
                    ? gallonsEstimate.toLocaleString('en-US')
                    : gallonsEstimate}
                  {' Gal'}
                </p>
              )}
            {service === FAT_BONES_SERVICE_NAME && (
              <p>
                {typeof barrelsEstimate === 'number'
                  ? barrelsEstimate.toLocaleString('en-US')
                  : barrelsEstimate}
                {' Barrels'}
              </p>
            )}
          </div>
        </div>
        <div className="mt-2 flex w-3/4 items-center justify-start gap-2">
          <LocationIcon style={{ width: '20px', height: '20px' }} />{' '}
          <p className="line-clamp-2 w-full truncate whitespace-normal">
            {addressLine1}
          </p>{' '}
        </div>
        <div className="mt-2 flex items-center justify-center">
          {showButtons && shouldShowButtons && (
            <RouteCardButton
              onManifestClick={handleManifest}
              onSkipStopClick={handleSkipStop}
              latitude={latitude}
              longitude={longitude}
              onDelayStopClick={handleDelayStop}
              onFocusClick={handleOnFocusClick}
            />
          )}
        </div>
      </div>
      <div className="container my-2 h-px w-60 bg-gray-300" />
    </div>
  );
};

export default CardStop;
