import { gql } from '@apollo/client';

export const SET_STOP_AS_FINISH = gql`
  mutation SetStopAsFinish(
    $stopId: String!
    $routeId: String!
    $total_gallons: Int
    $measuring_method: String
    $img_client_signature: Upload
    $textOther: String
    $services: String
    $pricePaid: Float
    $payment_type: String
    $payment_status: String
    $barrelsPickedUp: Int
    $barrelsDropped: Int
    $barrelSize: Int
  ) {
    setStopAsFinish(
      stopId: $stopId
      routeId: $routeId
      total_gallons: $total_gallons
      measuring_method: $measuring_method
      img_client_signature: $img_client_signature
      textOther: $textOther
      services: $services
      pricePaid: $pricePaid
      payment_type: $payment_type
      payment_status: $payment_status
      barrelsPickedUp: $barrelsPickedUp
      barrelsDropped: $barrelsDropped
      barrelSize: $barrelSize
    ) {
      status
      code
      data
      error
    }
  }
`;
