import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

// Importa tu consulta GraphQL
import { GET_EQUIPMENT } from '../services/graphql/settings/settings-query';
import { EquipmentStateAtom } from '../services/recoil/atoms/SettingState';

export function useSettingData() {
  const setEquipment = useSetRecoilState(EquipmentStateAtom);

  const { data, refetch } = useQuery(GET_EQUIPMENT);

  useEffect(() => {
    if (data && data.getEquipment) {
      const equipment = data.getEquipment.data;
      setEquipment(equipment);
    }
  }, [data]);

  return { data, refetch };
}
