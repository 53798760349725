import { gql } from '@apollo/client';

export const GET_DRIVER = gql`
  query GetUser($auth0Id: String) {
    getUser(auth0_id: $auth0Id) {
      code
      status
      data {
        id
        name
        phone
        email
        role
        profile_picture
        createdAt
        updatedAt
        deletedAt
        signature
      }
    }
  }
`;
