import { gql, useMutation } from '@apollo/client';

const CREATE_DRIVER_RECEIPT = gql`
  mutation CreateDriverReceipt(
    $file: Upload!
    $category: String!
    $route_id: String!
  ) {
    createDriverReceipt(file: $file, category: $category, route_id: $route_id) {
      status
      code
      data
      error
    }
  }
`;

export function useCreateDriverReceipt() {
  const [createDriverReceipt, { data, loading, error }] = useMutation(
    CREATE_DRIVER_RECEIPT,
  );

  return { createDriverReceipt, data, loading, error };
}
