import { gql } from '@apollo/client';

export const DELETE_DRIVER_RECEIPT = gql`
  mutation DeleteDriverReceipt($id: String!) {
    deleteDriverReceipt(id: $id) {
      status
      code
      data
      error
    }
  }
`;
