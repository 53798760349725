import { gql, useQuery } from '@apollo/client';

export const LINK_TRUCK = gql`
  query LinkTruck($userId: String!, $truckId: String!, $date: String!) {
    linkTruck(userId: $userId, truckId: $truckId, date: $date)
  }
`;

export function useLinkTruck() {
  const { data, loading, error, refetch } = useQuery(LINK_TRUCK, {
    skip: true,
  });

  return { data, loading, error, refetch };
}
