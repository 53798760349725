/* eslint-disable tailwindcss/no-custom-classname */
import { ReactComponent as CloseSquareIcon } from '../../icons/close-square.svg';
import { ReactComponent as WarningIcon } from '../../icons/warning.svg';
import AlertText from '../atoms/AlertText';
import PopupButton from '../atoms/PopupButton';

interface PopupProps {
  closeModal: () => void;
  openModal: () => void;
  iconColor: string;
  buttonFunction: any;
  buttonLabel: string;
  alert: string;
  message: string;
  idConfirm?: string;
  idCancel?: string;
}

function Popup({
  closeModal,
  iconColor,
  buttonFunction,
  buttonLabel,
  alert,
  message,
  idConfirm,
  idCancel,
}: PopupProps) {
  return (
    <>
      <div className="flex justify-end ">
        <button onClick={closeModal}>
          <CloseSquareIcon />
        </button>
      </div>
      <div className="flex justify-center">
        <WarningIcon fill={iconColor} />
      </div>
      <div className="w-100 flex-row">
        <AlertText alert={alert} warning={message} />
        <div className="flex flex-row items-center justify-center gap-4">
          <PopupButton
            label="Cancel"
            bgcolor="white"
            textcolor="ReyesGreen"
            id={idCancel}
            onClick={closeModal}
          />
          <PopupButton
            label={buttonLabel}
            id={idConfirm}
            bgcolor="ReyesGreen"
            textcolor="text-white"
            onClick={buttonFunction}
          />
        </div>
      </div>
    </>
  );
}

export default Popup;
