/* eslint-disable consistent-return */
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useRecoilValue } from 'recoil';

import { useCreateDriverReceipt } from '../../hooks/createDriverReceipt';
import { ReactComponent as ReceiptIcon } from '../../icons/receipt2.svg';
import { DELETE_DRIVER_RECEIPT } from '../../services/graphql/recipts/removeRecipt';
import { GET_ROUTE_BY_ID } from '../../services/graphql/routes/routes-querys';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import { tokenState } from '../../services/recoil/atoms/tokenState';
import { Loading } from '../atoms/Loader';
import Popup from '../molecules/Popup';

function Receipts() {
  const { t } = useTranslation();
  const token = useRecoilValue(tokenState);
  const [deleteDriverReceipt] = useMutation(DELETE_DRIVER_RECEIPT, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
  const [images, setImages] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [receiptToDelete, setReceiptToDelete] = useState<string | null>(null);
  const { createDriverReceipt } = useCreateDriverReceipt();
  const { register } = useForm();
  const commonStyles = 'relative flex items-center justify-center rounded-lg';
  const route = useRecoilValue(routeStateAtom);

  const { loading, data, refetch } = useQuery(GET_ROUTE_BY_ID, {
    variables: { routeId: route.id },
  });
  const handleUpload = async (file: File, routeId: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', 'Receipt');
    formData.append('route_id', routeId);

    const response = await createDriverReceipt({
      variables: {
        file,
        category: 'Receipt',
        route_id: routeId,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'createDriverReceipt',
        },
      },
    });
    if (response.data) {
      refetch();
    }
    if (response.data && response.data.createDriverReceipt.image) {
      setTimeout(async () => {
        const imageUrl = `${process.env.REACT_APP_FILE_URL}${response.data.createDriverReceipt.image}`;
        if (imageUrl) {
          const blob = await fetch(imageUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((res) => res.blob());

          setImages((prevImages) => [...prevImages, URL.createObjectURL(blob)]);
        }
      }, 1000);
    }
  };
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        const routeId = route.id;
        await handleUpload(file, routeId);
      }
    }
  };

  useEffect(() => {
    if (data) {
      Promise.all(
        data.getRouteById.data.driverReceipts.map(async (receipt: any) => {
          const imageUrl = `${process.env.REACT_APP_FILE_URL}${receipt.image}`;
          if (imageUrl) {
            const response = await fetch(imageUrl, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const blob = await response.blob();
            return URL.createObjectURL(blob);
          }
          return null;
        }),
      ).then(setImages);
    }
  }, [data, token]);

  if (loading)
    return (
      <>
        <Loading size={50} />
      </>
    );

  const handleDelete = (id: string) => {
    setReceiptToDelete(id);
    setModalIsOpen(true);
  };
  Modal.setAppElement('#root');
  const confirmDelete = async () => {
    if (receiptToDelete) {
      try {
        await deleteDriverReceipt({ variables: { id: receiptToDelete } });
        refetch();
      } catch (err) {
        console.error(err);
      }
    }
    setModalIsOpen(false);
  };

  return (
    <div className="flex flex-wrap justify-center gap-2">
      {images.map((src, index) => (
        <div key={index} className={`${commonStyles} h-[100px] w-[100px]`}>
          <img
            src={src}
            alt="Receipt"
            className="h-full w-full rounded-lg object-cover"
          />
          <button
            className="absolute right-0 top-0 flex h-6 w-6 items-center justify-center rounded-full bg-black text-white"
            onClick={() =>
              handleDelete(data.getRouteById.data.driverReceipts[index].id)
            }
          >
            X
          </button>
        </div>
      ))}
      <label
        htmlFor="fileInput"
        className={`${commonStyles} h-[100px] w-[108px] cursor-pointer bg-ReyesGreen`}
      >
        <div className="flex flex-col items-center justify-center rounded-lg">
          <span className="mb-2">
            <ReceiptIcon className="h-8 w-8" />
          </span>
          <span className="text-center text-white">{t('receipts.add')}</span>
        </div>
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          id="fileInput"
          {...register('file')}
          onChange={handleFileChange}
        />
      </label>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            maxWidth: '310px',
            maxHeight: '279px',
            margin: 'auto',
            borderRadius: '20px',
          },
        }}
        contentLabel="Alert"
      >
        <Popup
          closeModal={() => setModalIsOpen(false)}
          openModal={() => setModalIsOpen(true)}
          iconColor="#2E4347"
          buttonFunction={confirmDelete}
          buttonLabel="Delete"
          alert={t('receipts.alert')}
          message={t('receipts.message')}
        />
      </Modal>
    </div>
  );
}

export default Receipts;
