type PopupButtonProps = {
  bgcolor: string;
  textcolor: string;
  label: string;
  onClick: () => void;
  id?: string;
};

function PopupButton({
  textcolor,
  onClick,
  bgcolor,
  label,
  id,
}: PopupButtonProps) {
  const popupButtonClasses = `bg-${bgcolor} border border-ReyesGreen shadow-custom inline-flex h-15 w-30 items-center justify-start rounded px-2.5 py-2`;
  const textClases = `text-center text-base font-medium ${textcolor}`;
  return (
    <button onClick={onClick} className={popupButtonClasses} id={id}>
      <p className={textClases}>{label}</p>
    </button>
  );
}

export default PopupButton;
