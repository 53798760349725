import React from 'react';

type CardProps = {
  content: React.ReactNode;
  label: string;
  bgColor?: string;
  textColor?: string;
  classes?: string;
};

function Card({ content, label, bgColor, textColor, classes }: CardProps) {
  const cardClasses = `w-full h-20 px-2.5 py-1 ${
    textColor || 'text-ReyesBlack'
  }  text-xl font-black rounded-lg shadow-custom flex-col justify-center items-start gap-2.5 inline-flex overflow-hidden ${
    bgColor || 'bg-white'
  }`;
  const cardClass = classes || cardClasses;

  return (
    <div className={cardClass}>
      <div className="relative mt-2 flex h-3 w-full items-center justify-start text-center">
        {content}
      </div>
      <h1 className="w-full text-[22px]">{label}</h1>
    </div>
  );
}

export default Card;
