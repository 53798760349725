import { gql } from '@apollo/client';

export const CREATE_NOTE_MUTATION = gql`
  mutation CreateNote($note: String!, $userId: String!, $routeId: String!) {
    createNote(note: $note, userId: $userId, routeId: $routeId) {
      status
      code
      data
      error
    }
  }
`;
