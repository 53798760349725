import { useMutation } from '@apollo/client';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as ArrowIcon } from '../../icons/arrowcircleright.svg';
import { START_ROUTE_MUTATION } from '../../services/graphql/routes/routes-mutations';
import { initialMileageStateAtom } from '../../services/recoil/atoms/initialTruckMileageState';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import { EquipmentStateAtom } from '../../services/recoil/atoms/SettingState';
import { inputFormatNumber } from '../../utils/numberFormatter';

interface UpdateFormData {
  oil_level: 'High' | 'Medium' | 'Low' | '';
  antifreeze_level: 'High' | 'Medium' | 'Low' | '';
  tire_status: 'Good' | 'Bad' | '';
  def_level: 'High' | 'Medium' | 'Low' | '';
  gas_level: string;
  mileage: string;
  [key: string]: string;
}

interface Equipment {
  id: string;
  name: string;
  quantity: number;
  cost: number;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

function UpdateTruckInfoForm() {
  const route = useRecoilValue(routeStateAtom);
  const equipment = useRecoilValue(EquipmentStateAtom);
  const navigate = useNavigate();
  if (!route) {
    navigate('/dash');
    return null;
  }
  const setInitialMileage = useSetRecoilState(initialMileageStateAtom);

  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateFormData>({
    defaultValues: {
      oil_level: '',
      antifreeze_level: '',
      tire_status: '',
      def_level: '',
      gas_level: '',
      mileage: '',
    },
  });

  const [startRoute] = useMutation(START_ROUTE_MUTATION);
  const [displayValue, setDisplayValue] = useState('');

  const onSubmit: SubmitHandler<UpdateFormData> = (data) => {
    const mileageValue = parseInt(data.mileage, 10);
    setInitialMileage(mileageValue);

    const equipmentData = equipment?.map((equip: Equipment) => ({
      id: equip.id,
      quantity: data[equip.name],
      name: equip.name,
    }));

    startRoute({
      variables: {
        routeId: route.id,
        mileage: data.mileage,
        truckId: route.truck.id,
        oil_level: data.oil_level,
        antifreeze_level: data.antifreeze_level,
        tire_status: data.tire_status,
        def_level: data.def_level,
        gas_level: data.gas_level,
        equipment: JSON.stringify(equipmentData),
      },
    });
    navigate('/firststop');
  };
  const titleStyle = 'text-ReyesBlack text-sm font-normal';

  return (
    <div
      className="my-6 inline-flex h-[564.69px] w-[340px] flex-col items-start justify-start"
      id="UpdateTruckInfo"
    >
      <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className={titleStyle}> {t('updateinfo.formtitle')}</label>
          <div>
            <Controller
              name="oil_level"
              control={control}
              rules={{ required: `${t('manifest.error1')}` }}
              render={({ field }) => (
                <div className="mt-4 flex gap-2 ">
                  <button
                    className={`${
                      field.value === 'High'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start rounded-lg px-4  text-center shadow`}
                    type="button"
                    id="oilLevelHigh"
                    onClick={() => field.onChange('High')}
                  >
                    {t('updateinfo.high')}
                  </button>

                  <button
                    className={`${
                      field.value === 'Medium'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start rounded-lg px-4  text-center shadow`}
                    type="button"
                    onClick={() => field.onChange('Medium')}
                  >
                    {t('updateinfo.medium')}
                  </button>

                  <button
                    className={`${
                      field.value === 'Low'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    onClick={() => field.onChange('Low')}
                  >
                    {t('updateinfo.low')}
                  </button>
                </div>
              )}
            />
            {errors.oil_level && (
              <span className="ml-2 text-red-500">
                {errors.oil_level.message}
              </span>
            )}
          </div>
        </div>

        <div>
          <label className={titleStyle}> {t('updateinfo.antifreezer')}</label>
          <div>
            <Controller
              name="antifreeze_level"
              control={control}
              rules={{ required: `${t('manifest.error1')}` }}
              render={({ field }) => (
                <div className="mt-4 flex gap-2 ">
                  <button
                    className={`${
                      field.value === 'High'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    id="freezeLevelHigh"
                    onClick={() => field.onChange('High')}
                  >
                    {t('updateinfo.high')}
                  </button>

                  <button
                    className={`${
                      field.value === 'Medium'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    onClick={() => field.onChange('Medium')}
                  >
                    {t('updateinfo.medium')}
                  </button>

                  <button
                    className={`${
                      field.value === 'Low'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    onClick={() => field.onChange('Low')}
                  >
                    {t('updateinfo.low')}
                  </button>
                </div>
              )}
            />
            {errors.antifreeze_level && (
              <span className="ml-2 text-red-500">
                {errors.antifreeze_level.message}
              </span>
            )}
          </div>
        </div>

        <div>
          <label className={titleStyle}> {t('updateinfo.tire')}</label>
          <div>
            <Controller
              name="tire_status"
              control={control}
              rules={{ required: `${t('manifest.error1')}` }}
              render={({ field }) => (
                <div className="mt-4 flex gap-2 ">
                  <button
                    className={`${
                      field.value === 'Good'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    id="tireLevelHigh"
                    onClick={() => field.onChange('Good')}
                  >
                    {t('updateinfo.good')}
                  </button>

                  <button
                    className={`${
                      field.value === 'Bad'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    onClick={() => field.onChange('Bad')}
                  >
                    {t('updateinfo.bad')}
                  </button>
                </div>
              )}
            />
            {errors.tire_status && (
              <span className="ml-2 text-red-500">
                {errors.tire_status.message}
              </span>
            )}
          </div>
        </div>

        <div>
          <label className={titleStyle}> {t('updateinfo.def')}</label>
          <div>
            <Controller
              name="def_level"
              control={control}
              rules={{ required: `${t('manifest.error1')}` }}
              render={({ field }) => (
                <div className="mt-4 flex gap-2 ">
                  <button
                    className={`${
                      field.value === 'High'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    id="defLevelHigh"
                    onClick={() => field.onChange('High')}
                  >
                    {t('updateinfo.high')}
                  </button>

                  <button
                    className={`${
                      field.value === 'Medium'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    onClick={() => field.onChange('Medium')}
                  >
                    {t('updateinfo.medium')}
                  </button>

                  <button
                    className={`${
                      field.value === 'Low'
                        ? 'bg-ReyesGreen text-white'
                        : 'bg-white px-4 shadow'
                    } inline-flex h-12 max-w-max items-center justify-start  rounded-lg px-4  text-center shadow`}
                    type="button"
                    onClick={() => field.onChange('Low')}
                  >
                    {t('updateinfo.low')}
                  </button>
                </div>
              )}
            />
            {errors.def_level && (
              <span className="ml-2 text-red-500">
                {errors.def_level.message}
              </span>
            )}
          </div>
        </div>

        <div>
          <label className={titleStyle}> {t('updateinfo.verify')}</label>
          <br />
          <Controller
            name="gas_level"
            rules={{
              required: `${t('manifest.error2')}`,
              pattern: {
                value: /^[a-zA-Z0-9 ]*$/,
                message: `${t('updateinfo.error')}`,
              },
            }}
            control={control}
            render={({ field }) => (
              <input
                className="w-80 border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey ring-ReyesGreen focus:outline-none"
                type="text"
                id="gasLevel"
                {...field}
              />
            )}
          />
          {errors.gas_level && (
            <span className="ml-2 text-red-500">
              {errors.gas_level.message}
            </span>
          )}
        </div>

        <div>
          <label className={titleStyle}>{t('updateinfo.mileage')}</label>
          <br />
          <Controller
            name="mileage"
            control={control}
            rules={{
              required: `${t('manifest.error2')}`,
              pattern: /^[0-9]*$/,
              maxLength: 8,
            }}
            render={({ field }) => (
              <>
                <input
                  type="number"
                  id="mileageLevel"
                  {...field}
                  style={{ display: 'none' }}
                />
                <input
                  className="w-80 border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey ring-ReyesGreen focus:outline-none"
                  type="text"
                  id="mileageLevelDisplay"
                  value={displayValue}
                  maxLength={8}
                  onChange={(e) => {
                    const numberValue = Number(
                      e.target.value.replace(/,/g, ''),
                    );
                    if (!Number.isNaN(numberValue)) {
                      field.onChange(numberValue);
                      setDisplayValue(inputFormatNumber(numberValue));
                    }
                  }}
                  style={{ appearance: 'none', MozAppearance: 'textfield' }}
                />
              </>
            )}
          />
          {errors.mileage && (
            <span className="ml-2 text-red-500">{errors.mileage.message}</span>
          )}
        </div>
        <div>
          <label className=" font-inter text-base font-medium text-ReyesBlack">
            {t('updateinfo.Equipment')}
          </label>
          {equipment?.map((equip: any) => (
            <div className="mt-2">
              <label className={titleStyle}>
                {t('updateinfo.EquipmentNumber') + equip.name}
              </label>
              <br />
              <Controller
                name={equip.name}
                control={control}
                rules={{
                  required: `${t('manifest.error2')}`,
                  pattern: /^[0-9]*$/,
                  maxLength: 8,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="number"
                      id={equip.name}
                      {...field}
                      style={{ display: 'none' }}
                    />
                    <input
                      className="w-80 border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey ring-ReyesGreen focus:outline-none"
                      type="text"
                      id={equip.name}
                      maxLength={8}
                      onChange={(e) => {
                        const numberValue = Number(
                          e.target.value.replace(/,/g, ''),
                        );
                        field.onChange(numberValue);
                      }}
                      style={{ appearance: 'none', MozAppearance: 'textfield' }}
                    />
                  </>
                )}
              />
              {errors.mileage && (
                <span className="ml-2 text-red-500">
                  {errors.mileage.message}
                </span>
              )}
            </div>
          ))}
        </div>
        <button
          className="mb-6 mt-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded bg-ReyesGreen px-2.5 py-2 shadow-custom"
          type="submit"
          id="updateTruckInfoButton"
        >
          <p className="ml-2 text-center text-lg font-black text-white">
            {t('updateinfo.start')}
          </p>
          <ArrowIcon />
        </button>
      </form>
    </div>
  );
}
export default UpdateTruckInfoForm;
