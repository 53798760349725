import { gql } from '@apollo/client';

export const UPDATE_STOP = gql`
  mutation UpdateStop($stopId: String!, $reason: String!) {
    updateStop(
      key: "status"
      value: "Skipped"
      stopId: $stopId
      reason: $reason
    ) {
      status
      code
      data
      error
    }
  }
`;
