import { atom } from 'recoil';

interface Equipment {
  id: string;
  name: string;
  quantity: number;
  cost: number;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export const EquipmentStateAtom = atom<Equipment | null | any>({
  key: 'equipmentState',
  default: null,
});
