import { gql } from '@apollo/client';

export const GET_EQUIPMENT = gql`
  query GetEquipment {
    getEquipment {
      status
      code
      count
      error
      data {
        id
        name
        quantity
        cost
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
