import type { ReactNode } from 'react';
import React, { useState } from 'react';

import arrowdown2 from '../../icons/arrowdown2.svg';

interface DropDownProps {
  renderComponent: ReactNode;
  label: string;
  id?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  renderComponent,
  label,
  id = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mx-auto flex w-[90vw] max-w-full flex-col text-left">
      <button
        onClick={toggleDropdown}
        type="button"
        id={id}
        className="inline-flex h-7 w-full items-center justify-between border-b border-gray-400 pb-2 text-lg font-semibold text-ReyesBlack"
        aria-expanded={isOpen}
      >
        {label}
        <span className={`relative ml-2 h-5 w-5 ${isOpen ? 'rotate-180' : ''}`}>
          <img src={arrowdown2} alt="Arrow Down" width="22" height="22" />{' '}
        </span>
      </button>

      <div className="w-full">
        <div
          className={`right-0 mb-5 mt-2 flex w-full origin-top-right flex-col rounded-md ring-black ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          <div
            className="mt-3 py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="flex items-center justify-center">
              {renderComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
