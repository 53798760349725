/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import Modal from 'react-modal';

import { ReactComponent as CloseSquareIcon } from '../../icons/close-square.svg';
import { ReactComponent as SuccessIcon } from '../../icons/warning.svg';
import AlertText from '../atoms/AlertText';
import PopupButton from '../atoms/PopupButton';

interface SuccessPopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  alert: string;
  message: string;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({
  isOpen,
  onRequestClose,
  alert,
  message,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          maxWidth: '310px',
          maxHeight: '350px',
          margin: 'auto',
          borderRadius: '20px',
        },
      }}
      contentLabel="Success Alert"
    >
      <div className="flex justify-end ">
        <button onClick={onRequestClose}>
          <CloseSquareIcon />
        </button>
      </div>
      <div className="flex justify-center">
        <SuccessIcon fill="ReyesGreen" />
      </div>
      <div className="w-100 flex-row">
        <AlertText alert={alert} warning={message} />
        <div className="flex flex-row items-center justify-center gap-4">
          <PopupButton
            label="Confirm"
            id="ConfirmPopupButton"
            bgcolor="ReyesGreen"
            textcolor="text-white"
            onClick={onRequestClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuccessPopup;
