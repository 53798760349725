/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useTranslation } from 'react-i18next';

type StepState = {
  linkTruck?: boolean;
  update?: boolean;
  choose?: boolean;
  doneLinkTruck?: boolean;
  doneUpdate?: boolean;
};
type StepProps = {
  text: string;
  active?: boolean;
  done?: boolean;
  number: string;
};
function StatusBar({
  linkTruck,
  doneLinkTruck,
  doneUpdate,
  update,
  choose,
}: StepState) {
  const { t } = useTranslation();
  const containerClass = `inline-flex h-8 w-96 mr-3 overflow-x items-start gap-5 ${
    linkTruck ? 'justify-start' : update ? 'justify-center' : 'justify-end'
  }`;

  return (
    <div className={containerClass}>
      <Step
        text={t('progressBar.link')}
        number="1"
        active={linkTruck}
        done={doneLinkTruck}
      />
      <Step
        text={t('progressBar.update')}
        number="2"
        active={update}
        done={doneUpdate}
      />
      <Step text={t('progressBar.choose')} number="3" active={choose} />
    </div>
  );
}

function Step({ text, active, done, number }: StepProps) {
  const textClass = active ? 'text-black' : 'text-gray-500';
  let backgroundClass = 'bg-gray-300';

  if (done) {
    backgroundClass = 'bg-ReyesGold';
  } else if (active) {
    backgroundClass = 'bg-ReyesGreen';
  }
  return (
    <div
      className={`flex items-center justify-start gap-1.5 whitespace-nowrap ${textClass}`}
    >
      <div className="relative h-6 w-6 text-center text-white">
        <div
          className={`absolute left-0 top-0 h-6 w-6 ${backgroundClass} rounded-full`}
        >
          {number}
        </div>
      </div>
      <div className={`text-base font-medium`}>{text}</div>
    </div>
  );
}

export default StatusBar;
