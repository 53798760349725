// eslint-disable-next-line import/no-extraneous-dependencies
import { Html5Qrcode } from 'html5-qrcode';
import { useEffect, useState } from 'react';

const QrScanner = ({ onScan, onClose, modalStatus }: any) => {
  const [, setScanResult] = useState('');

  useEffect(() => {
    const html5QrCode = new Html5Qrcode('qrScanner');

    function success(result: string) {
      setScanResult(result);
      onScan(result);
      onClose();
      if (modalStatus) {
        html5QrCode
          .stop()
          .then((_ignore) => {
            console.log('QR Code scanning stopped');
            console.log('paro camara');
          })
          .catch((err) => {
            // Stop failed, handle it.
            console.log(`Unable to stop scanning, error: ${err}`);
          });
      }
    }

    function error(err: any) {
      if (!err.includes('NotFoundException')) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }

    Html5Qrcode.getCameras()
      .then((devices) => {
        /**
         * devices would be an array of objects of type:
         * { id: "id", label: "label" }
         */
        if (devices && devices.length) {
          html5QrCode
            .start(
              { facingMode: 'environment' },
              {
                fps: 10, // Optional, frame per seconds for qr code scanning
                qrbox: { width: 250, height: 400 }, // Optional, if you want bounded box UI
              },
              (decodedText) => {
                html5QrCode
                  .stop()
                  .then((_ignore) => {
                    console.log('QR Code scanning stopped');
                  })
                  .catch((err) => {
                    // Stop failed, handle it.
                    console.log(`Unable to stop scanning, error: ${err}`);
                  });
                success(decodedText);
                // do something when code is read
              },
              (errorMessage) => {
                error(errorMessage);
                // console.log('estado', html5QrCode.getState());
              },
            )
            .catch((err) => {
              // Start failed, handle it.
              console.log(`Unable to start scanning, error: ${err}`);
            });
        }
      })
      .catch((_err) => {
        // handle err
      });

    const scanRegion = document.getElementById('qrScanner');
    if (scanRegion) {
      const fileScanInput = scanRegion.getElementsByClassName(
        'html5-qrcode-file-scan-input',
      );
      if (fileScanInput.length > 0) {
        (fileScanInput[0] as HTMLElement).style.display = 'none';
      }
    }

    return () => {
      console.log('antes de cerrarlo', modalStatus, html5QrCode.getState());
      if (html5QrCode.getState() === 2) {
        html5QrCode
          .stop()
          .then((_ignore) => {
            console.log('QR Code scanning stopped');
            console.log('paro camara');
          })
          .catch((err) => {
            // Stop failed, handle it.
            console.log(`Unable to stop scanning, error: ${err}`);
          });
      }
    };
  }, [onScan, onClose, modalStatus]);

  return (
    <>
      <div
        className="flex h-full flex-col items-center justify-center"
        id="qrScanner"
      ></div>
    </>
  );
};

export default QrScanner;
