import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './services/translation/en/translation.json';
import esTranslation from './services/translation/es/translation.json';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: enTranslation,
    },
    es: {
      translation: esTranslation,
    },
  },
});

export default i18n;
