import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../icons/arrowcircleright.svg';
import { ReactComponent as CloseSquareIcon } from '../../icons/close-square.svg';
import { ReactComponent as Verify } from '../../icons/verify.svg';
import { ReactComponent as Warning } from '../../icons/warning.svg';
import { GET_ROUTECONFIRMATION_BY_ID } from '../../services/graphql/routes/routes-querys';
import ButtonCA from '../atoms/ButtonCA';
import { Loading } from '../atoms/Loader';
import Layout from './Layout';

type ConfirmRouteProps = {
  service: string;
  date: string;
  truck_license: string;
};

function ConfirmRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [info, setInfo] = useState<ConfirmRouteProps>();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { loading } = useQuery(GET_ROUTECONFIRMATION_BY_ID, {
    variables: { routeId: id },
    onCompleted: (data) => {
      setInfo(data.getRouteById.data);
    },
  });

  const confirmRoute = () => {
    fetch(`${process.env.REACT_APP_API}/confirmRoute?id=${id}`, {
      method: 'POST',
    }).then((res) => {
      if (res.status === 200) {
        setError(false);
        setModal(true);
      } else {
        setError(true);
        console.log('Error', res);
      }
    });
  };

  return (
    <>
      <Layout>
        {loading ? (
          <Loading size={50} />
        ) : (
          <div>
            <div className="mt-20 flex flex-col items-center justify-center text-center">
              <h1 className="font-inter text-xl font-extrabold text-black">
                {t('confirmRoute.title')}
              </h1>
              <p className="my-4 font-inter text-xs font-normal text-black">
                {t('confirmRoute.subTitle')}
              </p>
              <p className="font-inter text-lg font-bold text-black">
                {info?.date || 'No date found'}
              </p>
              <p className="font-inter text-lg font-bold text-black">
                {info?.service || 'No service found'}
              </p>
              <p className="font-inter text-lg font-bold text-black">
                {info?.truck_license || 'No license found'}
              </p>
              <button className="mt-8 w-full" onClick={confirmRoute}>
                <div className="flex h-[50px] w-full flex-row items-center justify-center overflow-hidden rounded-lg bg-ReyesGreen text-xl font-black text-white shadow-custom">
                  <h1 className="text-xl">{t('confirmRoute.button')}</h1>
                  <div className="ml-3">{<ArrowIcon />}</div>
                </div>
              </button>
            </div>
            <ButtonCA />
            <Modal
              isOpen={modal}
              onRequestClose={() => {
                setModal(false);
                if (!error) navigate('/dash');
              }}
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                  maxWidth: '310px',
                  maxHeight: '219px',
                  margin: 'auto',
                  borderRadius: '20px',
                },
              }}
              contentLabel="Alert"
            >
              <div>
                <div className="flex justify-end ">
                  <button
                    onClick={() => {
                      setModal(false);
                      if (!error) navigate('/dash');
                    }}
                  >
                    <CloseSquareIcon />
                  </button>
                </div>
                <div className="flex flex-col items-center justify-center text-center">
                  {error ? <Warning fill="#FF3333" /> : <Verify />}
                  <p className="my-2 font-inter text-lg font-bold">
                    {error
                      ? t('confirmRoute.error')
                      : t('confirmRoute.confirmed')}
                  </p>
                  <p className="font-inter text-xs">
                    {error
                      ? t('confirmRoute.errorMsg')
                      : t('confirmRoute.journeySet')}
                  </p>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </Layout>
    </>
  );
}

export default ConfirmRoute;
