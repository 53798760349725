import { gql } from '@apollo/client';

export const GET_ROUTE_BY_DRIVER = gql`
  query GetRouteByDriver($userId: String!, $date: String!) {
    getRouteByDriver(userId: $userId, date: $date) {
      status
      code
      error
      data {
        date
        estimated_gallons
        id
        payment_status
        picked_up_gallons
        route_name
        service
        status
        estimated_barrel
        picked_up_barrel
        stop_count
        stops {
          client_id
          createdAt
          date_time_arrived
          estimated_gallons
          id
          payment_type
          payments
          picked_up_gallons
          picked_up_barrel
          estimated_barrel
          route_id
          status
          updatedAt
          clientName
          clientAddress
          clientCoords {
            type
            coordinates
          }
          serviceContainerSize
          serviceBarrelSize
          clientPaid
        }
        truck {
          createdAt
          deletedAt
          fatBones_service
          fatBones_service_gallons
          grease_service
          grease_service_gallons
          hood_service
          hood_service_gallons
          id
          license
          make
          mileage
          model
          oil_service
          oil_service_gallons
          tk
          status
          updatedAt
        }
        truck_id
        user {
          updatedAt
          signature
          role
          profile_picture
          phone
          name
          id
          email
          deletedAt
          createdAt
        }
        user_id
      }
    }
  }
`;

export const GET_ROUTE_BY_ID = gql`
  query GetRouteById($routeId: String!) {
    getRouteById(routeId: $routeId) {
      status
      code
      error
      data {
        driverReceipts {
          id
          route_id
          amount
          createdAt
          updatedAt
          image
          category
          date
        }
      }
    }
  }
`;

export const GET_ROUTECONFIRMATION_BY_ID = gql`
  query GetRouteById($routeId: String!) {
    getRouteById(routeId: $routeId) {
      status
      code
      error
      data {
        date
        truck_license
        service
      }
    }
  }
`;
