import ButtonCA from '../atoms/ButtonCA';
import Title from '../atoms/Title';
import ProfileEditForm from '../forms/ProfileEditForm';
import Layout from './Layout';

function Profile() {
  return (
    <div>
      <Layout>
        <Title text={'Profile'} />
        <ProfileEditForm />
        <ButtonCA />
      </Layout>
    </div>
  );
}

export default Profile;
