import React from 'react';

type LongCardProps = {
  content: React.ReactNode;
  label: string;
  hoodCleaning?: boolean;
};

function LongCard({ content, label, hoodCleaning }: LongCardProps) {
  const width = hoodCleaning ? 'w-full' : 'w-[150px]';
  return (
    <>
      <div
        className={`inline-flex h-20 ${width} flex-row items-start  justify-center gap-2.5 overflow-hidden rounded-lg bg-white px-2.5 py-1 text-xl font-black text-ReyesBlack shadow-custom`}
      >
        <div className="flex flex-col items-center justify-center">
          {content}
          <h1>{label}</h1>
        </div>
      </div>
    </>
  );
}

export default LongCard;
