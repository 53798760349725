import type { ReactNode } from 'react';

import NavBar from '../molecules/NavBar';

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <>
      <section className="h-screen w-screen overflow-x-hidden bg-appBG py-2 font-inter">
        <NavBar />
        <div className="my-20 flex flex-col items-center ">
          <div className="container max-h-max w-full max-w-[90vw] ">
            {children}
          </div>
        </div>
      </section>
    </>
  );
}

export default Layout;
