/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { createUploadLink } from 'apollo-upload-client';

export const URI = process.env.REACT_APP_API_URI_GRAPHQL;

const ApolloWrapper = ({ children }: any) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const httpLink = createUploadLink({
    uri: URI,
  });

  // const errorLink = onError(({ graphQLErrors, networkError }) => {
  //   if (graphQLErrors) {
  //     const messages: string[] = [];
  //     graphQLErrors.forEach(({ message, locations, path }) => {
  //       console.log(
  //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
  //       );
  //       messages.push(message);
  //     });
  //   }
  //   if (networkError) console.log(`[Network error]: ${networkError}`);
  // });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    const auth0Id = user?.sub;
    return {
      headers: {
        ...headers,
        'apollo-require-preflight': true,
        authorization: token ? `Bearer ${token}` : '',
      },
      auth0Id,
    };
  });

  const client = new ApolloClient({
    link: from([authLink, httpLink]),
    // link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    uri: URI,
    credentials: 'include',
    defaultOptions: {},
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
