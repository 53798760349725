import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Loading } from '../components/atoms/Loader';
import { driverStateAtom } from '../services/recoil/atoms/driverState';
import { signatureStateAtom } from '../services/recoil/atoms/signatureState';
import { tokenState } from '../services/recoil/atoms/tokenState';

function ImageWithAuth() {
  const driver = useRecoilValue(driverStateAtom);
  const token = useRecoilValue(tokenState);
  const [imgSrc, setImgSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New loading state

  useEffect(() => {
    const loadImage = async () => {
      if (token && driver?.signature) {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_FILE_URL}${driver?.signature}&${Date.now()}`, // Add timestamp as query parameter
          {
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              'apollo-require-preflight': 'true',
              'Accept-Encoding': 'gzip, deflate, br',
            }),
          },
        );
        const blob = await response.blob();
        const imgURL = URL.createObjectURL(blob);
        setImgSrc(imgURL);
        setIsLoading(false);
      }
    };

    loadImage();
  }, [driver?.signature, token, signatureStateAtom]);

  return isLoading ? (
    <Loading size={50} />
  ) : (
    <div className="flex w-full items-center justify-center">
      {imgSrc && <img src={imgSrc} alt="Driver's signature" className="w-80" />}
    </div>
  );
}

export default ImageWithAuth;
