// /* eslint-disable unused-imports/no-unused-vars */
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Manifest from './components/forms/Manifest';
import SkipForm from './components/forms/SkipForm';
import ConfirmRoute from './components/templates/ConfirmRoute';
import Dashboard from './components/templates/Dashboard';
import FinishRoute from './components/templates/FinishRoute';
import FinishRouteTruckMileage from './components/templates/FinishRouteTruckMileage';
import FirstStop from './components/templates/FirstStop';
import Profile from './components/templates/Profile';
import Receipt from './components/templates/Receipt';
import StartQR from './components/templates/StartQR';
import StartRoute from './components/templates/StartRoute';
import StartRouteManual from './components/templates/StartRouteManual';
import UpdateInfo from './components/templates/UpdateInfo';
import { useDriverData } from './hooks/useDriverData';
import { useRouteData } from './hooks/useRouteData';
import { useSettingData } from './hooks/useSettingData';
import i18n from './i18n';
import { authStateAtom } from './services/recoil/atoms/authState';
import { driverStateAtom } from './services/recoil/atoms/driverState';
import { tokenState } from './services/recoil/atoms/tokenState';

function App() {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const setAuthState = useSetRecoilState(authStateAtom);
  const setTokenState = useSetRecoilState(tokenState);
  const url = window.location.href;

  useEffect(() => {
    (async () => {
      if (!isAuthenticated && !isLoading && !error) {
        loginWithRedirect({
          authorizationParams: {
            redirect_uri: url,
          },
        });
      }
      if (user && user.sub) {
        const authInfo = user.sub;
        const token = await getAccessTokenSilently();
        setAuthState(authInfo);
        setTokenState(token);
      }
    })();
  }, [
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    setAuthState,
    user,
  ]);

  /**
   * Initialize Datadog RUM
   */
  useEffect(() => {
    if (!datadogRum.getInternalContext()) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: process.env.REACT_APP_DATADOG_SITE || '',
        service: process.env.REACT_APP_DATADOG_SERVICE || '',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
      });
    }

    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (user && datadogRum) {
      datadogRum.setUser({
        id: user.sub,
        email: user.email,
        name: user.name,
      });
    }
  }, [user, datadogRum]);

  useDriverData(user?.sub);
  const driverInfo = useRecoilValue(driverStateAtom);
  useRouteData(driverInfo?.id);
  useSettingData();
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <Routes>
          <Route path="/" element={isAuthenticated && <Dashboard />} />
          <Route path="/start" element={<StartRoute />} />
          <Route path="/dash" element={<Dashboard />} />
          <Route path="/finishroute" element={<FinishRoute />} />
          <Route
            path="/finishroutecheckmileage"
            element={<FinishRouteTruckMileage />}
          />
          <Route path="/startqr" element={<StartQR />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/manifest" element={<Manifest />} />
          <Route path="/startroutemanual" element={<StartRouteManual />} />
          <Route path="/updateinfo" element={<UpdateInfo />} />
          <Route path="/firststop" element={<FirstStop />} />
          <Route path="/skipstop" element={<SkipForm />} />
          <Route path="/receipt" element={<Receipt />} />
          <Route path="/confirmRoute" element={<ConfirmRoute />} />
        </Routes>
      </I18nextProvider>
    </>
  );
}

export default App;
