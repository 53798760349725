import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import { activeStopStateAtom } from '../../services/recoil/atoms/stopState';

// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const markerElement = () => {
  // Create a DOM element for each marker.
  const el = document.createElement('div');
  const WIDTH = 15;
  const HEIGHT = 15;
  el.className = 'marker';
  el.style.backgroundImage = `url(/svg/map-marker.svg)`;
  el.style.width = `${WIDTH}px`;
  el.style.height = `${HEIGHT}px`;
  el.style.backgroundSize = '100%';
  return el;
};

type Props = {
  full?: boolean;
  medium?: boolean;
  className?: string;
};

const Map: React.FC<Props> = ({ full = false, medium = false }) => {
  const mapDiv = useRef<HTMLDivElement>(null);
  const routeState = useRecoilValue(routeStateAtom);
  const activeStop = useRecoilValue(activeStopStateAtom);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);

  useEffect(() => {
    if (!mapDiv.current || map) {
      return;
    }
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      return;
    }
    while (mapDiv.current.firstChild) {
      mapDiv.current.removeChild(mapDiv.current.firstChild);
    }

    const mapInstance = new mapboxgl.Map({
      accessToken: process.env.REACT_APP_PUBLIC_MAPBOX_GL_ACCESS_TOKEN ?? '',
      container: mapDiv.current!,
      style: process.env.REACT_APP_PUBLIC_MAPBOX_GL_STYLE ?? '',
      center: { lat: 34.053182, lng: -118.1179 },
      zoom: 8,
      dragPan: false,
      doubleClickZoom: false,
      scrollZoom: false,
      boxZoom: false,
      dragRotate: false,
      keyboard: false,
      touchZoomRotate: false,
    });

    setMap(mapInstance);
    if (routeState.stops.length > 0) {
      routeState.stops
        .filter((stop: { status: string }) => stop.status === 'Pending')
        .forEach((stop: { clientCoords: { coordinates: [any, any] } }) => {
          const [stopLatitude, stopLongitude] = stop.clientCoords.coordinates;
          if (
            stopLatitude >= -90 &&
            stopLatitude <= 90 &&
            stopLongitude >= -180 &&
            stopLongitude <= 180
          ) {
            new mapboxgl.Marker(markerElement())
              .setLngLat([stopLongitude, stopLatitude])
              .addTo(mapInstance);
          } else {
            console.warn(
              `Invalid coordinates: ${stopLongitude}, ${stopLatitude}`,
            );
          }
        });
    }
    if (routeState.stops.length > 0 && !activeStop) {
      // Calculate map center based on routes
      const lats = routeState.stops
        .filter((stop: { status: string }) => stop.status === 'Pending')
        .map((stop: any) => stop.clientCoords.coordinates[0]);
      const lngs = routeState.stops
        .filter((stop: { status: string }) => stop.status === 'Pending')
        .map((stop: any) => stop.clientCoords.coordinates[1]);

      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);

      if (lats.length > 0 && lngs.length > 0)
        mapInstance.on('load', () => {
          mapInstance.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            {
              padding: { top: 50, bottom: 50, left: 50, right: 50 },
              maxZoom: 13,
            },
          );
        });
    }
  }, [routeState, full, medium, map]);

  useEffect(() => {
    if (!map) return;
    if (map && activeStop) {
      const [stopLatitude, stopLongitude] = activeStop.clientCoords.coordinates;
      map.flyTo({
        center: [stopLongitude, stopLatitude],
        zoom: 15,
        duration: 1500,
        // essential: true,
      });
    }
  }, [map, activeStop]);

  if (full) {
    return (
      <div
        ref={mapDiv}
        className="flex h-[75vh] w-full items-center justify-center"
      />
    );
  }
  if (medium) {
    return (
      <div
        ref={mapDiv}
        className="h-[308px] w-[90vw] items-center justify-center rounded-lg shadow-custom"
      />
    );
  }
  return (
    <div ref={mapDiv} className="h-[200px] w-[90vw] rounded-lg shadow-custom" />
  );
};

export default Map;
