/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { Link } from 'react-router-dom';

import { ReactComponent as ElementIcon } from '../../icons/element.svg';
import { ReactComponent as UserIcon } from '../../icons/useroctagon.svg';

interface NavProps {
  label?: string;
}
function Navbar(props: NavProps) {
  const { label } = props;

  return (
    <div className="fixed left-0 top-0 z-10 mx-auto h-[50px] w-full backdrop-blur-[2px]">
      <nav className="mx-auto flex h-[50px] w-full max-w-[90vw] flex-row items-center justify-between">
        <Link to="/dash">
          <div className="relative h-6 w-6 shrink-0">
            <ElementIcon />
          </div>
        </Link>

        <h1 className="text-xl font-bold text-ReyesBlack">{label}</h1>
        <Link to="/profile">
          <div className="relative h-6 w-6 shrink-0">
            <UserIcon />
          </div>
        </Link>
      </nav>
    </div>
  );
}

export default Navbar;
