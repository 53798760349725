/* eslint-disable @typescript-eslint/no-use-before-define */
import { useTranslation } from 'react-i18next';

type StepState = {
  verifyRouteInfo?: boolean;
  unlinkTruck?: boolean;
  doneVerifyRouteInfo?: boolean;
  doneUnlinkTruck?: boolean;
};

type StepProps = {
  text: string;
  active?: boolean;
  done?: boolean;
  number: string;
};

function FinishBar({
  verifyRouteInfo,
  doneVerifyRouteInfo,
  unlinkTruck,
  doneUnlinkTruck,
}: StepState) {
  const containerClass = `w-[90vw] inline-flex h-6 w-96 my-3 items-start gap-5 ${
    verifyRouteInfo ? 'justify-start' : 'justify-end'
  }`;
  const { t } = useTranslation();

  return (
    <div className={containerClass}>
      <Step
        text={t('finishBar.verify')}
        number="1"
        active={verifyRouteInfo}
        done={doneVerifyRouteInfo}
      />
      <Step
        text={t('finishBar.unlink')}
        number="2"
        active={unlinkTruck}
        done={doneUnlinkTruck}
      />
    </div>
  );
}

function Step({ text, active, done, number }: StepProps) {
  const textClass = active ? 'text-black' : 'text-gray-500';
  let backgroundClass = 'bg-gray-300';

  if (done) {
    backgroundClass = 'bg-ReyesGold';
  } else if (active) {
    backgroundClass = 'bg-ReyesGreen';
  }
  return (
    <div
      className={`flex items-center justify-start gap-1.5 whitespace-nowrap ${textClass}`}
    >
      <div className="relative h-6 w-6 text-center text-white">
        <div
          className={`absolute left-0 top-0 h-6 w-6 ${backgroundClass} rounded-full`}
        >
          {number}
        </div>
      </div>
      <div className={`text-base font-medium`}>{text}</div>
    </div>
  );
}

export default FinishBar;
