import { gql } from '@apollo/client';

export const GET_NOTES_QUERY = gql`
  query GetRouteById($routeId: String!) {
    getRouteById(routeId: $routeId) {
      status
      code
      error
      data {
        notes {
          id
          user_id
          route_id
          note
          createdAt
          updatedAt
          deletedAt
          client_id
          user_name
        }
      }
    }
  }
`;
