import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { routeStateAtom } from '../../services/recoil/atoms/routeState';
// import { activeStopStateAtom } from '../../services/recoil/atoms/stopState';
import Map from '../atoms/Mapbox';
import InfoCard from '../molecules/InfoCard';
import NavBarMap from '../molecules/NavBarMap';
import RouteOnList from '../organism/RouteOnList';

function FirstStop() {
  const route = useRecoilValue(routeStateAtom);
  const navigate = useNavigate();

  if (!route) {
    navigate('/dash');
    return null;
  }
  // const activeStop = useRecoilValue(activeStopStateAtom);
  useEffect(() => {
    if (route.stops.every((stop: any) => stop.status !== 'Pending')) {
      navigate('/finishroute');
    }
  }, [route]);
  return (
    <>
      {/* {activeStop ? ( */}
      <div className="relative h-screen w-full ">
        <div className="absolute top-0 w-full">
          <NavBarMap label={route?.route_name} />
        </div>
        <Map full={true} className="absolute inset-0 z-0" />
        <div className="absolute bottom-0 flex w-full flex-col items-center justify-center">
          <div className="z-10 w-[95vw]">
            <InfoCard />
          </div>
          <div className="z-10 h-[40vh] w-full overflow-y-scroll rounded-t-3xl">
            <RouteOnList />
          </div>
        </div>
      </div>
      {/* ) : (
        <Layout>
          <div>
            <Title text={'Starting Route'}></Title>
            <div className="mb-8">
              <StatusBar choose={true} doneLinkTruck={true} doneUpdate={true} />
            </div>
          </div>
          <span className="container my-8 flex flex-col items-center justify-center pt-8">
            <Map medium={true} />
            <StopsList />
          </span>
        </Layout>
      )} */}
    </>
  );
}

export default FirstStop;
