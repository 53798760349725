import { atom } from 'recoil';

interface Driver {
  [x: string]: any;
  id: string;
  name: string;
  phone: string;
  email: string;
  role: string;
  profile_picture: File;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  signature: string;
}
export const driverStateAtom = atom<Driver | null>({
  key: 'driverState',
  default: null,
});
