/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { CREATE_NOTE_MUTATION } from '../../services/graphql/notes/notesMutation';
import { GET_NOTES_QUERY } from '../../services/graphql/notes/notesQuery';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';

const Notes: React.FC = () => {
  const { register, handleSubmit, reset } = useForm();
  const route = useRecoilValue(routeStateAtom);
  const driver = useRecoilValue(driverStateAtom);
  const [createNote] = useMutation(CREATE_NOTE_MUTATION);

  const { loading, error, data, refetch } = useQuery(GET_NOTES_QUERY, {
    variables: { routeId: route.id },
  });

  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    createNote({
      variables: {
        note: data.notes,
        userId: driver?.id,
        routeId: route.id,
      },
    }).then(() => {
      reset();
      refetch();
    });
  };
  return (
    <div id="noteContainer">
      {loading ? (
        <p>{t('notes.loading')}</p>
      ) : error ? (
        <p>{t('notes.error')}</p>
      ) : (
        data?.getRouteById?.data?.notes?.map((note: any) => {
          const createdAt = Number(note.createdAt);
          return (
            <div key={note.id}>
              <div className="flex flex-row justify-between gap-4">
                <p className="truncate text-sm font-bold">{note.user_name} </p>
                <p className="truncate text-sm font-normal text-zinc-600">
                  {new Date(createdAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </p>
              </div>
              <p className="text-sm">{note.note}</p>
              <div className="container my-4 h-px w-60 bg-gray-300" />
            </div>
          );
        })
      )}
      <div className="relative z-0 flex justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <textarea
            {...register('notes')}
            id="noteTextArea"
            className="h-[300px] w-[90vw] overflow-hidden text-ellipsis p-4 text-sm font-normal"
            style={{ resize: 'none', borderRadius: '5px' }}
          />
          <button
            type="submit"
            className="inline-flex items-center justify-center gap-2.5 self-end rounded-lg bg-gray-700 px-5 py-1 font-semibold text-white"
            id="SendNoteButton"
            style={{ position: 'absolute', right: '20px', bottom: '20px' }}
          >
            {t('notes.addNote')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Notes;
