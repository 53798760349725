import { useTranslation } from 'react-i18next';

import { ReactComponent as BuildingIcon } from '../../icons/building.svg';
import { ReactComponent as DoneIcon } from '../../icons/clipboardtick.svg';
import { ReactComponent as SkippedIcon } from '../../icons/locationcross.svg';

interface CardDoneProps {
  businessName: string;
  status: string;
}

const CardDone: React.FC<CardDoneProps> = ({
  businessName,
  status,
}: CardDoneProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col">
      <div className="my-2 flex w-full items-center justify-between px-4 text-sm font-normal text-ReyesBlack">
        <div className="flex w-2/3 items-center gap-2">
          <div className="h-[20px] w-[20px] flex-none">
            <BuildingIcon width="20" height="20" />
          </div>
          <p className="truncate">{businessName}</p>
        </div>
        <div className="flex w-1/3 flex-row items-center justify-end gap-1">
          <div className="h-[20px] w-[20px] flex-none">
            {(status === 'Completed' ||
              status === 'In Review' ||
              status === 'Waiting Payments') && (
              <DoneIcon width="20" height="20" />
            )}
            {status === 'Skipped' && <SkippedIcon width="20" height="20" />}
            {/* {status === 'Waiting Payments' && (
              <SkippedIcon width="20" height="20" />
            )} */}
            {/* {status === 'In Review' && <SkippedIcon width="20" height="20" />} */}
          </div>
          <p>
            {status === 'Completed' ||
            status === 'In Review' ||
            status === 'Waiting Payments'
              ? t('infoCard.done')
              : status}
          </p>
        </div>
      </div>
      <div className="container my-2 h-px w-60 bg-gray-300" />
    </div>
  );
};

export default CardDone;
