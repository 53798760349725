import { atom } from 'recoil';

interface Stop {
  id: string;
  pickedUpGallons: number;
  routeId: string;
  status: string;
  estimatedGallons: number;
  estimatedPounds: number;
  clientId: string;
  clientName: string;
  serviceContainerSize: number;
}

interface Truck {
  id: string;
  make: string;
  mileage: number;
  model: string;
  hoodService: string;
  hoodServiceGallons: number;
  status: string;
}

interface RouteInfo {
  stops: Stop[];
  truck: Truck;
  route_name: string;
  stop_count: number;
  status: string;
  pickedUpGallonsPounds: number;
  estimated_gallons: number;
  estimated_barrel: number;
  picked_up_barrel: number;
  id: string;
}

export const routeStateAtom = atom<RouteInfo | null | any>({
  key: 'routeState',
  default: null,
});
