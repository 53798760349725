import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useCreateDriverReceipt } from '../../hooks/createDriverReceipt';
import { formatDateWithTime } from '../../hooks/dateFormatter';
import { ReactComponent as ArrowIcon } from '../../icons/arrowcircleright.svg';
import { ReactComponent as ImageIcon } from '../../icons/image.svg';
import { mutationStateAtom } from '../../services/recoil/atoms/mutationState';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import { Loading } from '../atoms/Loader';
import Title from '../atoms/Title';
import Layout from './Layout';

function Receipt() {
  const { t } = useTranslation();
  const route = useRecoilValue(routeStateAtom);
  const navigate = useNavigate();

  if (!route) {
    navigate('/dash');
    return null;
  }
  const currentDate = new Date();
  const formattedDate = formatDateWithTime(currentDate);
  const { register } = useForm();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleRemoveImage = () => {
    setFile(null);
    setPreviewImage(null);
  };
  const setMutationState = useSetRecoilState(mutationStateAtom);
  const handleMutation = () => {
    setMutationState((prevState) => !prevState);
  };

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const { createDriverReceipt } = useCreateDriverReceipt();

  const handleUpload = async () => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('category', 'Receipt');
      formData.append('route_id', route?.id);
      await createDriverReceipt({
        variables: {
          file,
          category: 'Receipt',
          route_id: route?.id,
        },
        context: {
          headers: {
            'content-type': 'multipart/form-data',
            'x-apollo-operation-name': 'createDriverReceipt',
          },
        },
      });
      handleMutation();
      navigate('/firstStop');
      setLoading(false);
    }
  };
  return (
    <>
      <Layout>
        {loading ? (
          <Loading size={50} />
        ) : (
          <span className="m-6 flex-col justify-center space-y-8">
            <Title text={t('receipts.title')}></Title>
            <div className="flex flex-col gap-6">
              <h2 className="text-lg font-semibold text-ReyesBlack ">
                {t('receipts.secondTitle')}
              </h2>
              <span>
                <p className="text-sm text-ReyesBlack">{t('receipts.date')}</p>
                <p className="text-sm text-ReyesGrey">{formattedDate}</p>
              </span>
              <span>
                <p className="mb-2 text-sm text-ReyesBlack">
                  {t('receipts.upload')}
                </p>
                {previewImage && (
                  <div className="relative mx-auto my-5 flex justify-center">
                    <div className="relative h-72 w-64">
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="h-full w-full object-contain"
                      />
                      <button
                        className="absolute right-0 top-0 flex h-6 w-6 items-center justify-center rounded-full bg-black text-white"
                        onClick={handleRemoveImage}
                      >
                        X
                      </button>
                    </div>
                  </div>
                )}
                <span className="flex justify-center gap-3">
                  <div className="inline-flex h-[100px] w-[340px] flex-col items-center justify-center gap-2 rounded-[10px] bg-white px-2.5">
                    <label
                      htmlFor="fileInput"
                      className="relative cursor-pointer flex-col items-center justify-center rounded-lg py-2"
                    >
                      <div className="flex flex-col items-center">
                        <span className="mb-2">
                          <ImageIcon className="h-8 w-8" />
                        </span>
                        <span className="text-center">
                          {t('receipts.upload1')}
                        </span>
                      </div>
                      <input
                        type="file"
                        accept="image"
                        style={{ display: 'none' }}
                        id="fileInput"
                        {...register('file')}
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </span>
              </span>
              <button
                type="button"
                className="mb-2 mr-2 inline-flex items-center gap-2 rounded-xl bg-ReyesGreen px-5 py-2.5 text-center text-xl font-bold text-white"
                onClick={handleUpload}
                id="uploadReceipt"
              >
                {t('receipts.button')} <ArrowIcon />
              </button>
            </div>
          </span>
        )}
      </Layout>
    </>
  );
}

export default Receipt;
