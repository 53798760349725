import { gql } from '@apollo/client';

export const UPDATE_ROUTE_MUTATION = gql`
  mutation UpdateRoute($routeId: String!, $status: String!) {
    updateRoute(routeId: $routeId, status: $status) {
      status
      code
      data
      error
    }
  }
`;

export const START_ROUTE_MUTATION = gql`
  mutation StartRoute(
    $routeId: String!
    $mileage: Int!
    $truckId: String!
    $oil_level: String!
    $antifreeze_level: String!
    $tire_status: String!
    $def_level: String!
    $gas_level: String!
    $equipment: String
  ) {
    startRoute(
      routeId: $routeId
      mileage: $mileage
      truckId: $truckId
      oil_level: $oil_level
      antifreeze_level: $antifreeze_level
      tire_status: $tire_status
      def_level: $def_level
      gas_level: $gas_level
      equipment: $equipment
    ) {
      status
      code
      data
      error
    }
  }
`;

export const FINISH_ROUTE = gql`
  mutation FinishRoute($routeId: String!, $mileage: Int!, $equipment: String) {
    finishRoute(routeId: $routeId, mileage: $mileage, equipment: $equipment) {
      status
      code
      data
      error
    }
  }
`;
