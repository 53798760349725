import { useRecoilValue, useSetRecoilState } from 'recoil';

import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import { showButtonsStateAtom } from '../../services/recoil/atoms/showButtonsStateAtom';
import { activeStopStateAtom } from '../../services/recoil/atoms/stopState';
import { Loading } from '../atoms/Loader';
import CardDone from '../molecules/CardDone';
import CardStop from '../molecules/CardStop';

interface Stop {
  id: any;
  clientName: string;
  estimated_gallons: string;
  estimated_barrel: string;
  clientAddress: string;
  status: string;
}

function StopsList() {
  const route = useRecoilValue(routeStateAtom);
  const routeStops = route?.stops || [];
  const setActiveStop = useSetRecoilState(activeStopStateAtom);
  const setShowButtons = useSetRecoilState(showButtonsStateAtom);
  const activeStop = useRecoilValue(activeStopStateAtom);

  const handleStopSelect = (stop: Stop) => {
    setActiveStop(stop);
    setShowButtons(stop.id);
  };

  return (
    <span
      className="w-[92vw] flex-col items-center justify-center gap-3 py-2"
      id="stopList"
    >
      {routeStops ? (
        <>
          {routeStops
            .filter((stop: Stop) => stop.status === 'Pending')
            .map((stop: Stop) => (
              <div key={stop.id} onClick={() => handleStopSelect(stop)}>
                <CardStop
                  businessName={stop.clientName}
                  gallonsEstimate={stop.estimated_gallons}
                  barrelsEstimate={stop.estimated_barrel}
                  addressLine1={stop.clientAddress}
                  isActive={stop.id === activeStop?.id}
                  shouldShowButtons={
                    stop.id === useRecoilValue(showButtonsStateAtom)
                  }
                  service={route.service}
                />
              </div>
            ))}
          {routeStops
            .filter((stop: Stop) => stop.status !== 'Pending')
            .map((stop: Stop) => (
              <div className="flex w-full justify-center" key={stop.id}>
                <CardDone businessName={stop.clientName} status={stop.status} />
              </div>
            ))}
        </>
      ) : (
        <Loading size={35} />
      )}
    </span>
  );
}

export default StopsList;
