/* eslint-disable tailwindcss/no-custom-classname */
interface TitleProps {
  firstName?: string;
  text: string;
  textSpan?: string;
}

function Title({ firstName, text, textSpan }: TitleProps) {
  return (
    <div className="mb-8 text-left text-xl font-black text-ReyesBlack">
      <h1>
        {text} {firstName ? `${firstName},` : ''}
      </h1>
      <h1> {textSpan}</h1>
    </div>
  );
}

export default Title;
