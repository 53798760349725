/* eslint-disable tailwindcss/no-custom-classname */
import { useTranslation } from 'react-i18next';

import { ReactComponent as CallIcon } from '../../icons/call.svg';

function ButtonCA() {
  const { t } = useTranslation();
  const phoneNumber = process.env.REACT_APP_ASSIST_PHONE_NUMBER;

  return (
    <div className=" flex flex-col items-center justify-center gap-2.5">
      <a
        href={`tel:${phoneNumber}`}
        className="fixed bottom-5 inline-flex h-9 w-52  items-center rounded-3xl bg-ReyesGreen"
        id="callAssistance"
      >
        <div className="relative h-5 w-5" />
        <CallIcon />
        <div className="ml-2 text-center text-base font-medium text-white">
          {t('buttonCA.label')}
        </div>
      </a>
    </div>
  );
}

export default ButtonCA;
