import { atom } from 'recoil';

interface ActiveStop {
  id: string;
  pickedUpGallons: number;
  routeId: string;
  status: string;
  estimatedGallons: number;
  estimatedPounds: number;
  clientId: string;
  clientCoords: {};
  serviceBarrelSize: number;
}

export const activeStopStateAtom = atom<ActiveStop | null | any>({
  key: 'activeStopState',
  default: null,
});
