/* eslint-disable no-unsafe-optional-chaining */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { FAT_BONES_SERVICE_NAME } from '../../config/constants';
import { ReactComponent as FlagIcon } from '../../icons/flag.svg';
import { ReactComponent as ReceiptIcon } from '../../icons/receipt2.svg';
import { ReactComponent as TruckIcon } from '../../icons/trucktick.svg';
import { ReactComponent as WeightmeterIcon } from '../../icons/weightmeter.svg';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';

export default function InfoCard() {
  const route = useRecoilValue(routeStateAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLinkClick = () => {
    navigate('/receipt');
  };

  const handleFinishClick = () => {
    navigate('/finishroute');
  };
  const galLeft =
    Number(route?.estimated_gallons || 0) -
    Number(route?.picked_up_gallons || 0);

  const barrelLeft =
    Number(route?.estimated_barrel || 0) - Number(route?.picked_up_barrel || 0);

  const completedOrSkippedStops = route?.stops.filter(
    (stop: { status: string }) => stop.status !== 'Pending',
  ).length;
  const stopsLeft = route?.stop_count - completedOrSkippedStops;
  return (
    <>
      <div className="flex w-full justify-between py-3">
        <span className="ml-3">
          <div className="mb-2 flex gap-4">
            <WeightmeterIcon width="24" height="24" />
            {route?.service !== FAT_BONES_SERVICE_NAME && (
              <p>
                {galLeft?.toLocaleString('en-US')} /{' '}
                {route?.estimated_gallons?.toLocaleString('en-US') || 0}
                {' Gal'}
              </p>
            )}
            {route?.service === FAT_BONES_SERVICE_NAME && (
              <p>
                {barrelLeft?.toLocaleString('en-US')} /{' '}
                {route?.estimated_barrel?.toLocaleString('en-US') || 0}
                {' Barrels'}
              </p>
            )}
          </div>
          <div className="flex  gap-4">
            <TruckIcon />
            <p>
              {stopsLeft} / {route?.stop_count}
              {' Stops'}
            </p>
          </div>
        </span>
        <span className="flex items-center justify-center gap-1">
          <button
            type="button"
            className="inline-flex items-center gap-2 rounded-xl bg-ReyesGreen px-3 py-1.5 text-center text-lg font-bold text-white"
            onClick={handleFinishClick}
          >
            {t('infoCard.finish')} <FlagIcon />
          </button>
          <button
            type="button"
            onClick={handleLinkClick}
            className="inline-flex items-center gap-2 rounded-xl bg-ReyesGrey px-3 py-1.5 text-center text-lg font-bold text-white"
          >
            + <ReceiptIcon />
          </button>
        </span>
      </div>
    </>
  );
}
