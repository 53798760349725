import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  FAT_BONES_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
} from '../../config/constants';
import { ReactComponent as Flag } from '../../icons/flag.svg';
import { ReactComponent as TruckIcon } from '../../icons/truck.svg';
import { ReactComponent as WeightmeterIcon } from '../../icons/weightmeter.svg';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import Button from '../atoms/Button';
import Title from '../atoms/Title';
import Notes from '../forms/Notes';
import Receipts from '../forms/Receipts';
import DropDown from '../molecules/DropDown';
import FinishBar from '../molecules/FinishRouteBar';
import StopsSummary from '../organism/StopsSumary';
import Layout from './Layout';

function FinishRoute() {
  const { t } = useTranslation();
  const route = useRecoilValue(routeStateAtom);
  const navigate = useNavigate();

  if (!route) {
    navigate('/dash');
    return null;
  }
  const uncompleteStopsCount = route.stops.filter(
    (stop: { status: string }) => stop.status === 'Pending',
  ).length;

  return (
    <Layout>
      <Title text={t('finishRoute.title')} />
      <FinishBar verifyRouteInfo={true} />
      <div className="my-5 flex max-w-full items-center justify-around gap-8">
        {route.service !== HOOD_CLEANING_SERVICE_NAME && (
          <div className="flex flex-col items-center">
            <div className="flex items-center gap-2">
              <div className="w-6">
                <WeightmeterIcon width="24" height="24" />
              </div>
              {route?.service !== FAT_BONES_SERVICE_NAME && (
                <p className="text-center text-lg font-semibold text-ReyesBlack">
                  {t('finishRoute.totalGallons')}
                </p>
              )}
              {route?.service === FAT_BONES_SERVICE_NAME && (
                <p className="text-center text-lg font-semibold text-ReyesBlack">
                  {t('finishRoute.totalBarrels')}
                </p>
              )}
            </div>
            {route?.service !== FAT_BONES_SERVICE_NAME && (
              <span>
                {route?.picked_up_gallons?.toLocaleString('en-US') || '0'} /{' '}
                {route?.estimated_gallons?.toLocaleString('en-US') || '0'}
              </span>
            )}
            {route?.service === FAT_BONES_SERVICE_NAME && (
              <span>
                {route?.picked_up_barrel?.toLocaleString('en-US') || '0'} /{' '}
                {route?.estimated_barrel?.toLocaleString('en-US') || '0'}
              </span>
            )}
          </div>
        )}
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-2">
            <div className="w-6">
              <TruckIcon width="24" height="24" />
            </div>
            <p className="text-center text-lg font-semibold text-ReyesBlack">
              {t('finishRoute.stops')}
            </p>
          </div>
          <span>
            <p>
              {uncompleteStopsCount} / {route?.stop_count}
            </p>
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-5 ">
        <div className="">
          <DropDown
            renderComponent={<StopsSummary />}
            label={t('finishRoute.stopsSummary')}
          />
        </div>

        <div className="">
          <DropDown
            renderComponent={<Notes />}
            label={t('finishRoute.notes')}
            id="noteDropdown"
          />
        </div>
        <div className="">
          <DropDown
            renderComponent={<Receipts />}
            label={t('finishRoute.receipts')}
          />
        </div>
        <Link to="/finishroutecheckmileage">
          <Button
            id="NextButton"
            icon={<Flag />}
            label={t('finishRoute.next')}
            color="ReyesGreen"
            width="[90vw]"
          />
        </Link>
      </div>
    </Layout>
  );
}

export default FinishRoute;
