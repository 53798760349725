interface AlertTextProps {
  alert: string;
  warning: string;
}

function AlertText({ alert, warning }: AlertTextProps) {
  return (
    <div className="m-4 flex flex-col justify-center align-middle">
      <p className="text-center text-xl font-semibold text-ReyesBlack">
        {alert}
      </p>
      <p className="text-center text-xs font-normal text-ReyesBlack">
        {warning}
      </p>
    </div>
  );
}

export default AlertText;
