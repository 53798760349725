/* eslint-disable unused-imports/no-unused-vars */
import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useDriverData } from '../../hooks/useDriverData';
import ImageWithAuth from '../../hooks/useDriverSignature';
import type { ProfileData } from '../../hooks/useProfileData';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as LogOutIcon } from '../../icons/logout.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import {
  CHANGE_PASSWORD,
  UPDATE_SIGNATURE,
  UPDATE_USER,
} from '../../services/graphql/drivers/drivers-mutations';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { signatureStateAtom } from '../../services/recoil/atoms/signatureState';
import SignatureCanvas from '../atoms/Signature';
import ErrorPopup from '../molecules/ErrorPopup';
import Popup from '../molecules/Popup';
import SuccessPopup from '../molecules/SuccessPopup';

function ProfileEditForm() {
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  const { control, handleSubmit } = useForm<ProfileData>();
  const driver = useRecoilValue(driverStateAtom);
  const navigate = useNavigate();

  if (!driver) {
    navigate('/dash');
  }
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { logout, user } = useAuth0();
  const { t } = useTranslation();

  const [isEditingSignature, setIsEditingSignature] = useState(false);
  const [isEditing, setIsEditing] = useState({
    name: false,
    phone: false,
    password: false,
  });

  const logoutRedirect = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };
  const [signature, setSignature] = useState<File | null>(null);

  const { refetch: refetchDriverData } = useDriverData(user?.sub);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const iconColor = '#2E4347';
  const setsignatureState = useSetRecoilState(signatureStateAtom);
  const handlesignature = () => {
    setsignatureState((prevState) => !prevState);
  };

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setIsSuccessPopupOpen(true);
      refetchDriverData();
    },
  });

  const [updateSignature] = useMutation(UPDATE_SIGNATURE, {
    onCompleted: () => {
      handlesignature();
      setIsSuccessPopupOpen(true);
      setIsEditingSignature(false);
      setSignature(null);
      refetchDriverData();
    },
  });

  const onSubmit = async (data: ProfileData) => {
    if (driver) {
      Object.entries(data).forEach(([key, value]) => {
        if (
          (key === 'name' && value !== driver.name) ||
          (key === 'phone' && value !== driver.phone)
        ) {
          updateUser({
            variables: {
              key,
              value,
              userid: driver.id,
            },
          });
        }
      });

      if (signature) {
        await updateSignature({
          variables: {
            file: signature,
            type: 'signature',
            id: driver.id,
          },
        });
      }

      setIsEditing({ name: false, phone: false, password: false });
    }
  };

  const cancelSignature = () => {
    setIsEditingSignature(false);
    setSignature(null);
  };

  const handleChangePassword = async () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    if (!oldPassword || !newPassword || !confirmedPassword) {
      setIsChangingPassword(false);
      return;
    }

    if (newPassword !== confirmedPassword) {
      setIsErrorPopupOpen(true);
      setNewPassword('');
      setConfirmedPassword('');
      return;
    }
    if (passwordRegex.test(newPassword)) {
      try {
        const response = await changePasswordMutation({
          variables: {
            oldPassword,
            newPassword,
            email: driver?.email,
            auth0_id: user?.sub,
          },
        });
        setIsChangingPassword(false);
        if (response.data.changePassword) {
          setIsSuccessPopupOpen(true);
        } else {
          throw new Error('Password change failed');
        }
      } catch (error) {
        setIsErrorPopupOpen(true);
        setOldPassword('');
        setNewPassword('');
        setConfirmedPassword('');
      }
    }
    setIsErrorPopupOpen(true);
    setNewPassword('');
    setConfirmedPassword('');
  };

  const handleConfirmClick = () => {
    handleChangePassword();
    setIsChangingPassword(false);
  };

  Modal.setAppElement('#root');
  return (
    <div className="flex items-center justify-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mx-4 inline-flex w-full flex-col items-start justify-center gap-8 overflow-hidden"
      >
        <div className="flex w-full flex-col">
          <label className="my-4">{t('profileform.name')}</label>
          <div className="flex items-center justify-between">
            <Controller
              control={control}
              name="name"
              defaultValue={driver?.name}
              rules={{
                pattern: {
                  value: /^[A-Za-z\s]+$/i,
                  message: `${t('profileform.error1')}`,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="relative">
                  {isEditing.name ? (
                    <input
                      {...field}
                      id="editName"
                      className="w-80 border-b-2 border-gray-300 bg-transparent p-2"
                    />
                  ) : (
                    <div className="grow flex-col" id="Name">
                      {field.value}
                    </div>
                  )}
                  {error && (
                    <span className="text-red-500">{error.message}</span>
                  )}
                </div>
              )}
            />
            <EditIcon
              id="editNameIcon"
              className="ml-2"
              onClick={() => setIsEditing({ ...isEditing, name: true })}
            />
          </div>
          {isEditing.name && (
            <div className="flex w-full flex-row justify-center">
              <button
                type="submit"
                className=" 
              mt-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded-xl bg-ReyesGreen px-2.5 py-2 text-center text-lg font-black text-white shadow-custom"
                id="saveChangesName"
              >
                <p>{t('profileform.saveChanges')}</p>
                <SaveIcon />
              </button>
            </div>
          )}
        </div>

        <div className="flex w-full flex-col">
          <label className="my-4">{t('profileform.phone')}</label>
          <div className="flex items-center justify-between">
            <Controller
              control={control}
              name="phone"
              defaultValue={driver?.phone}
              rules={{
                pattern: {
                  value: /^[0-9+]+$/i,
                  message: `${t('profileform.error2')}`,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="relative">
                  {isEditing.phone ? (
                    <input
                      {...field}
                      className="w-80 border-b-2 border-gray-300 bg-transparent p-2"
                    />
                  ) : (
                    <div className="grow flex-col">{field.value}</div>
                  )}
                  {error && (
                    <span className="text-red-500">{error.message}</span>
                  )}
                </div>
              )}
            />
            <EditIcon
              className="ml-2"
              onClick={() => setIsEditing({ ...isEditing, phone: true })}
            />
          </div>
          {isEditing.phone && (
            <div className="flex w-full flex-row justify-center">
              <button
                type="submit"
                className=" 
                mt-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded-xl bg-ReyesGreen px-2.5 py-2 text-center text-lg font-black text-white shadow-custom"
                id="saveChangesName"
              >
                <p>{t('profileform.saveChanges')}</p>
                <SaveIcon />
              </button>
            </div>
          )}
        </div>

        <div className="flex w-full flex-col">
          <label className="my-4">{t('profileform.label1')}</label>
          <div className="flex items-center justify-between">
            {isChangingPassword ? (
              <div className="flex flex-col items-start justify-start">
                <label className="my-4">{t('profileform.label2')}</label>
                <input
                  type="password"
                  className="w-80 border-b-2 border-gray-300 bg-transparent p-2"
                  placeholder={t('profileform.placeholder1')}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <label className="my-4">{t('profileform.label3')}</label>
                <input
                  type="password"
                  className="w-80 border-b-2 border-gray-300 bg-transparent p-2"
                  placeholder={t('profileform.placeholder2')}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
                <label className="my-4">
                  {t('profileform.confirmPassword')}
                </label>
                <input
                  type="password"
                  className="w-80 border-b-2 border-gray-300 bg-transparent p-2"
                  placeholder={t('profileform.placeholder3')}
                  onChange={(e) => setConfirmedPassword(e.target.value)}
                />
              </div>
            ) : (
              <div className="grow" onClick={() => setIsChangingPassword(true)}>
                **********
              </div>
            )}
            <EditIcon
              className="ml-2"
              onClick={() => setIsChangingPassword(true)}
            />
          </div>
          {isChangingPassword && (
            <div>
              <button
                type="submit"
                className=" 
              mt-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded-xl bg-ReyesGreen px-2.5 py-2 text-center text-lg font-black text-white shadow-custom"
                onClick={handleConfirmClick}
              >
                <p>{t('profileform.saveChanges')}</p>
                <SaveIcon />
              </button>
            </div>
          )}
        </div>
        <div className="flex w-full flex-row justify-between">
          <p>{t('profileform.signature')}</p>
          <EditIcon
            className="ml-2"
            onClick={() => setIsEditingSignature(true)}
          />
        </div>
        {isEditingSignature ? (
          <div className="flex w-full flex-col items-center justify-center overflow-visible">
            <SignatureCanvas
              onSignatureSave={setSignature}
              clearText="Clear Signature"
            />
            {signature && (
              <button
                type="submit"
                className="mt-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded-xl bg-ReyesGreen px-2.5 py-2 text-center text-lg font-black text-white shadow-md"
              >
                <p>{t('profileform.saveChanges')}</p>
                <SaveIcon />
              </button>
            )}
            <button
              type="button"
              onClick={cancelSignature}
              className="my-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded-xl bg-ReyesGreenExtraLight px-2.5 py-2 text-center text-lg font-black text-white shadow-md"
            >
              <p>{t('profileform.cancel')}</p>
              <CancelIcon />
            </button>
          </div>
        ) : (
          <>
            <ImageWithAuth />
          </>
        )}
        <button
          type="button"
          onClick={openModal}
          id="logoutButton"
          className=" 
              mt-2 inline-flex h-12 w-full items-center justify-start gap-4 rounded-xl bg-ReyesGrey px-2.5 py-2 text-center text-lg font-black text-white shadow-md hover:bg-ReyesRed"
        >
          <p>{t('profileform.logout')}</p>
          <LogOutIcon />
          <div className="relative h-6 w-6" />
        </button>
      </form>
      <ErrorPopup
        errorisOpen={isErrorPopupOpen}
        onRequestClose={() => setIsErrorPopupOpen(false)}
        alert={t('profileform.errorPopup')}
        message={t('profileform.errorPopupMssg')}
      />
      <SuccessPopup
        isOpen={isSuccessPopupOpen}
        onRequestClose={() => setIsSuccessPopupOpen(false)}
        alert={t('profileform.successPopup')}
        message={t('profileform.successPopupMssg')}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            maxWidth: '310px',
            maxHeight: '320px',
            margin: 'auto',
            borderRadius: '20px',
          },
        }}
        contentLabel="Alert"
      >
        <Popup
          closeModal={closeModal}
          openModal={openModal}
          iconColor={iconColor}
          buttonFunction={logoutRedirect}
          idConfirm="logoutConfirm"
          idCancel="logoutCancel"
          buttonLabel={t('profileform.logout')}
          alert={t('profileform.popuplabel')}
          message={t('profileform.popupmssg')}
        />
      </Modal>
    </div>
  );
}

export default ProfileEditForm;
