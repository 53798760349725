/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';

type ButtonProps = {
  icon: React.ReactNode;
  label: string;
  color?: string;
  justify?: string;
  id?: string;
  width: string;
  onClick?: () => void;
};

function Button({
  icon,
  label,
  color,
  justify,
  id,
  width,
  onClick,
}: ButtonProps) {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`bg-${color} mt-2 inline-flex h-12 w-${width} items-center justify-${justify} gap-2.5 rounded-lg px-2.5 py-2 shadow-custom`}
    >
      <p className="text-center text-lg font-black text-white">{label}</p>
      {icon}
    </button>
  );
}

export default Button;
