import { useRecoilValue } from 'recoil';

import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import CardStopSummary from '../molecules/CardStopSummary';

function StopsSummary() {
  const routeStops = useRecoilValue(routeStateAtom)?.stops || [];
  const route = useRecoilValue(routeStateAtom);

  return (
    <div className="inline-flex w-full flex-col items-center justify-center gap-4">
      {routeStops.map((stop: any) => (
        <ul key={stop.id}>
          <CardStopSummary
            key={stop.id}
            businessName={stop.clientName}
            gallonsEstimate={stop.picked_up_gallons}
            barrelsEstimate={stop.picked_up_barrel}
            addressLine1={stop.clientAddress}
            status={stop.status}
            service={route.service}
          />
        </ul>
      ))}
    </div>
  );
}

export default StopsSummary;
