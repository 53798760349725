/* eslint-disable tailwindcss/no-custom-classname */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ReactComponent as ArrowIcon } from '../../icons/arrowcircleright.svg';
import { ReactComponent as TruckIcon } from '../../icons/truck.svg';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import Button from '../atoms/Button';
import ButtonCA from '../atoms/ButtonCA';
import Title from '../atoms/Title';
import Layout from './Layout';

function StartRoute() {
  const driver = useRecoilValue(driverStateAtom);
  const route = useRecoilValue(routeStateAtom);
  const truck = route?.truck;
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="">
        <span className="max-w-[215px]">
          <Title firstName={driver?.name} text={t('startroute.titleText')} />
        </span>
        <h2 className="my-6 text-base font-semibold text-ReyesBlack ">
          {t('startroute.subtitle')}
        </h2>

        <div className="">
          <div className="inline-flex flex-col items-start justify-start gap-6">
            <div className="inline-flex h-16 w-32 flex-col items-start justify-start gap-2">
              <p className="text-xs font-normal text-ReyesBlack">
                {t('startroute.card1')}
              </p>
              <div className="inline-flex items-center justify-start gap-2 rounded-lg bg-white p-2 shadow-custom">
                <TruckIcon />
                <p className="text-base font-semibold text-ReyesBlack ">
                  {truck.license}
                </p>
              </div>
            </div>
            <div className="inline-flex h-16 flex-col items-start justify-start gap-2">
              <div className="text-xs font-normal text-ReyesBlack ">
                {t('startroute.card2')}
              </div>
              <div className="max-w-[90vw] whitespace-nowrap rounded-lg bg-white p-2 shadow-custom">
                <p className="truncate text-base font-semibold text-ReyesBlack">
                  {truck.make} - {truck.model}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-11 flex w-full justify-center">
          <Link to="/startqr" id="LinkTruckButton" className="w-full">
            <Button
              justify="start"
              icon={<ArrowIcon />}
              label={t('startroute.modalbutton')}
              color="ReyesGreen"
              width="full"
            />
          </Link>
        </div>
      </div>
      <ButtonCA />
    </Layout>
  );
}

export default StartRoute;
