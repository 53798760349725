export function formatDateWithTime(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const day = date.getDate();
  const suffixes = ['th', 'st', 'nd', 'rd'];
  let i = day % 100;

  if (i > 3 && i < 21) i = 0;
  if (i > 3) i = 0;
  const suffix = suffixes[i];
  const dayWithSuffix = day.toString() + suffix;

  return `${date
    .toLocaleDateString('en-US', options)
    .replace(/\d+/, dayWithSuffix)}, ${date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })}`;
}
