import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../icons/back.svg';

function MyComponent() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative h-6 w-6 shrink-0">
      <button onClick={handleGoBack} id="handleback">
        <BackIcon />
      </button>
    </div>
  );
}

export default MyComponent;
