import { useMutation } from '@apollo/client';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useRouteData } from '../../hooks/useRouteData';
import { ReactComponent as Flag } from '../../icons/flag.svg';
import { FINISH_ROUTE } from '../../services/graphql/routes/routes-mutations';
// import { UPDATE_TRUCK_MILEAGE } from '../../services/graphql/trucks/trucks-mutations';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { initialMileageStateAtom } from '../../services/recoil/atoms/initialTruckMileageState';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import { EquipmentStateAtom } from '../../services/recoil/atoms/SettingState';
import { inputFormatNumber } from '../../utils/numberFormatter';
import Title from '../atoms/Title';
import FinishBar from '../molecules/FinishRouteBar';
import Layout from './Layout';

interface UpdateFormData {
  mileage: string;
  [key: string]: string;
}

interface Equipment {
  id: string;
  name: string;
  quantity: number;
  cost: number;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

function FinishRouteTruckMileage() {
  const route = useRecoilValue(routeStateAtom);
  const navigate = useNavigate();
  const equipment = useRecoilValue(EquipmentStateAtom);

  // if (!route) {
  //   navigate('/dash');
  //   return null;
  // }
  const driver = useRecoilValue(driverStateAtom);
  const { refetch } = useRouteData(driver?.id);
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateFormData>({
    defaultValues: {
      mileage: '',
    },
  });
  const initialMileage = useRecoilValue(initialMileageStateAtom);

  // const [updateTruck] = useMutation(UPDATE_TRUCK_MILEAGE);
  const [finishRoute] = useMutation(FINISH_ROUTE);
  const [displayValue, setDisplayValue] = useState('');

  const onSubmit: SubmitHandler<UpdateFormData> = async (data) => {
    const mileageValue = parseInt(data.mileage, 10);
    // await updateTruck({
    //   variables: {
    //     truckId: route.truck.id,
    //     mileage: mileageValue,
    //   },
    // });
    const equipmentData = equipment?.map((equip: Equipment) => ({
      id: equip.id,
      quantity: data[equip.name],
      name: equip.name,
    }));

    await finishRoute({
      variables: {
        routeId: route.id,
        mileage: mileageValue,
        equipment: JSON.stringify(equipmentData),
      },
    });
    await refetch();
    navigate('/dash');
  };

  return (
    <Layout>
      <Title text={t('finishRoute.title')} />
      <FinishBar
        doneVerifyRouteInfo={true}
        unlinkTruck={true}
        verifyRouteInfo={false}
      />
      <form
        className="mt-4 flex flex-col items-center justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full text-left">
          <label className="text-sm font-normal text-ReyesBlack">
            {t('updateinfo.mileage')}
          </label>
        </div>
        <div className="flex w-full flex-col items-center">
          <Controller
            name="mileage"
            control={control}
            rules={{
              required: `${t('finishRoute.mileageRequiredError')}`,
              pattern: /^[0-9]*$/,
              maxLength: 8,
              validate: (value) =>
                parseInt(value, 10) >= initialMileage
                  ? true
                  : `${t('finishRoute.mileageAmountError')}`,
            }}
            render={({ field }) => (
              <>
                <input
                  type="number"
                  id="mileageFinish"
                  {...field}
                  style={{ display: 'none' }}
                />
                <input
                  className="mt-6 w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none"
                  type="text"
                  id="mileageFinishDisplay"
                  value={displayValue}
                  maxLength={8}
                  onChange={(e) => {
                    const numberValue = Number(
                      e.target.value.replace(/,/g, ''),
                    );
                    if (!Number.isNaN(numberValue)) {
                      field.onChange(numberValue);
                      setDisplayValue(inputFormatNumber(numberValue));
                    }
                  }}
                  style={{ appearance: 'none', MozAppearance: 'textfield' }}
                />
              </>
            )}
          />
          {errors.mileage && (
            <span className="mt-2 text-red-500">{errors.mileage.message}</span>
          )}
        </div>
        <div className="mt-5 w-full">
          <label className=" font-inter text-base font-medium text-ReyesBlack">
            {t('updateinfo.Equipment')}
          </label>
          {equipment?.map((equip: any) => (
            <div className="mt-2 w-full">
              <label className="text-sm font-normal text-ReyesBlack">
                {t('updateinfo.EquipmentNumber') + equip.name}
              </label>
              <br />
              <Controller
                name={equip.name}
                control={control}
                rules={{
                  required: `${t('manifest.error2')}`,
                  pattern: /^[0-9]*$/,
                  maxLength: 8,
                }}
                render={({ field }) => (
                  <>
                    <input
                      type="number"
                      id={equip.name}
                      {...field}
                      style={{ display: 'none' }}
                    />
                    <input
                      className="w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey ring-ReyesGreen focus:outline-none"
                      type="text"
                      id={equip.name}
                      maxLength={8}
                      onChange={(e) => {
                        const numberValue = Number(
                          e.target.value.replace(/,/g, ''),
                        );
                        field.onChange(numberValue);
                      }}
                      style={{ appearance: 'none', MozAppearance: 'textfield' }}
                    />
                  </>
                )}
              />
              {errors[equip.name] && (
                <span className="ml-2 text-red-500">
                  {errors[equip.name]?.message}
                </span>
              )}
            </div>
          ))}
        </div>
        <button
          className="mt-8 inline-flex h-12 w-[340px] items-center justify-start gap-2.5 rounded bg-ReyesGreen px-2.5 py-2 shadow-custom"
          type="submit"
          id="FinishRouteMileage"
        >
          <p className="ml-2 text-center text-lg font-black text-white">
            {t('dashboard.buttonOnGoing3')}
          </p>
          <Flag />
        </button>
      </form>
    </Layout>
  );
}

export default FinishRouteTruckMileage;
