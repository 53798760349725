const ButtonsManifestCarousel = ({
  field,
  options,
  setState,
  state,
  multi = false,
}: {
  field: any;
  options: string[];
  setState: any;
  state: any;
  multi?: boolean;
}) => {
  // Estado para manejar las selecciones múltiples

  const handleButtonClick = (option: string) => {
    if (multi) {
      let newSelectedOptions = [];
      if (state.includes(option)) {
        // Si la opción ya está seleccionada, la eliminamos
        newSelectedOptions = state.filter((opt: any) => opt !== option);
      } else {
        // Si la opción no está seleccionada, la añadimos
        newSelectedOptions = [...state, option];
      }
      setState(newSelectedOptions);
      // Actualizar el valor del campo con las nuevas opciones seleccionadas
      field.onChange(newSelectedOptions);
    } else {
      setState([option]);
      // Actualizar el valor del campo con las nuevas opciones seleccionadas
      field.onChange([option]);
    }
  };

  return (
    <div className="flex w-full gap-2 overflow-x-auto py-1" id="hideScrollBar">
      {options.map((option: string) => (
        <button
          key={option}
          className={`${
            state.includes(option)
              ? 'bg-ReyesGreen text-white'
              : 'bg-white px-4 shadow'
          } h-12 items-center justify-start whitespace-nowrap rounded-lg px-4 text-center shadow`}
          type="button"
          onClick={() => handleButtonClick(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default ButtonsManifestCarousel;
