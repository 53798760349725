import StopsList from './StopsList';

function RouteOnList() {
  return (
    <div className="z-20 flex w-full flex-col items-center justify-center overflow-y-auto rounded-t-3xl bg-appBG">
      <div className="flex w-[92vw] flex-col items-center justify-center gap-3 py-2">
        <StopsList />
      </div>
    </div>
  );
}

export default RouteOnList;
